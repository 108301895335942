@media screen and (min-width: 768px) {
  app-custom-product-card .custom-product-card {
    border-right: 1px solid var(--lib-color-neutral-200);
    border-bottom: 1px solid var(--lib-color-neutral-200);
  }
  app-custom-product-card:nth-child(2n) .custom-product-card {
    border-right: none;
  }
  app-custom-product-card:nth-child(3n) .custom-product-card {
    border-right: 1px solid var(--lib-color-neutral-200);
  }
}
@media screen and (min-width: 1280px) {
  app-custom-product-card:nth-child(2n) .custom-product-card {
    border-right: 1px solid var(--lib-color-neutral-200);
  }
  app-custom-product-card:nth-child(3n) .custom-product-card {
    border-right: none;
  }
}
.custom-product-card {
  display: flex;
  flex-flow: column;
  background-color: #fff;

  padding: 32px 0;

  height: auto;
  .details-text {
    height: 8rem;
    overflow: hidden;

    .text {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media screen and (min-width: 768px) {
    padding: 32px 22px;
    min-height: 100%;
  }
  @media screen and (min-width: 1280px) {
    padding: 32px;
  }
  .allocation-p {
    margin: 0 0 17px 0;
    padding: 10px 0 0 0;
  }
  .prod-allocation {
    color: #f03c3c;
  }
  .custom-card-header {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-content: center;
  }
  .custom-card-body {
    flex: 2 1;
    display: flex;
    flex-flow: column;
    .info-tabs {
      width: 100%;
      margin: 12px 0 0 0;
    }
    .custom-tab-title {
      font-family: "NotoSansBold", sans-serif;
      font-size: 1em;
      font-weight: bold;
    }
  }
  .custom-tab-p p {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .white {
    background-color: #238ae6;
    color: #ffffff;
  }
  .blue {
    color: #238ae6;
  }
  .custom-card-footer {
    display: flex;
    flex-flow: column;
    justify-content: center;
    .blue-button {
      background: #0057a6;
      font-size: 1.1em;
      text-transform: inherit;
      font-family: "NotoSansBold", sans-serif;
      color: #ffffff;
      opacity: 1;
      width: 100%;
      height: 44px;
      border: 0;
    }
  }

  .custom-tab-title {
    margin: 0px 0px 10px 0px;
  }
  .save-price {
    color: #36a657;
  }

  .volume-table {
    background-color: #edf6fb;
  }
  .padding-0 {
    margin-left: -12px;
    margin-top: 5px;
  }
  .price-total {
    font-size: 1.7em;
    font-family: "Poppins-Medium", sans-serif;
    font-weight: 500;
    color: #2a343f;
  }
  .positive-green-icon {
    width: 30px;
  }

  .prod-quantity {
    width: 44px;
    height: 44px;
    border-radius: 4px;
    border: solid 1px #d4d9de;
    box-shadow: inset 0 0 3px 0 0 #d4d9e3;
    margin: 0px 6px;
  }

  .price-section {
    padding: 20px 0 0 0;
  }

  .pdf-icon {
    width: 7%;
    margin: 0 0 0 -3px;
  }

  .file-link {
    font-size: 1em;
  }

  .product-img {
    max-height: 191px;
  }

  .fa.disabled {
    color: #c8cdd2;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .removeContent {
    display: none;
  }

  .dosesInput {
    border: none;
    pointer-events: none;
    width: 50%;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  @media screen and (min-width: 300px) and (max-width: 650px) {
    .dosesInput {
      border: none;
      pointer-events: none;
      width: 20%;
    }
    .units {
      padding: 0px 16px;
    }
    .unit-calc .price-details {
      width: 29%;
      margin: 0 20px 0 0;
    }
    .unit-calc {
      display: flex;
      flex-wrap: wrap;
    }
    .unit-calc .w-100 {
      margin: 0 0 0 -42px;
    }
  }
  @media screen and (min-width: 926px) {
    .unit-calc .price-details {
      width: 50%;
      margin: 5px 0px 0px 0px;
    }

    .modal-backdrop {
      display: none;
    }

    .unit-calc .units {
      width: 50%;
      margin: 20px 0px 20px 0px;
    }
  }

  .unit-calc .button-calc {
    width: 100%;
  }

  .product-discontinued-txt1 {
    font-size: 1em;
    color: #de1848;
    font-family: "NotoSansBold", sans-serif;
    line-height: 25px;
    margin: 15% 0 4% 0;
  }

  .disabled-img {
    display: none !important;
    cursor: auto;
  }

  .unit-area {
    cursor: pointer;
  }

  .units {
    text-align: center;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .unit-calc .price-details {
      width: 50%;
      margin: 0px 0px 0px -13px;
    }
    .m0-spacing {
      margin: 10px 0px;
    }
    .unit-calc .units {
      width: 50%;
      margin: 0px 0px 10px 13px !important;
    }
    .custom-product-card {
      padding: 32px 15px !important;
    }
    .unit-calc {
      margin: 10px 0px 10px 0px;
    }
    .unit-area {
      margin: 5px 0px 0px 0px;
    }
    .units {
      text-align: center;
      margin: 0px 0px 10px 13px;
    }
    .dosessection {
      margin: 0px 0px 10px 0px;
    }
  }
  @media screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
    .units {
      text-align: center;
      margin: 0px 0px 10px 38px !important;
    }
    .unit-calc {
      margin: 10px 0px 10px 0px;
    }
    .m0-spacing {
      margin: 10px 0px;
    }
  }
  @media screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
    .unit-calc .price-details {
      width: 50%;
      margin: 0 0px 0 -13px;
    }
    .unit-calc {
      margin: 10px 0px 10px 0px;
    }
    .units {
      padding: 0px 0px;
      margin: 0px 0px 0px 35px;
    }
    .dosessection {
      margin: 0px 0px 10px 0px;
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .price-details {
      width: 47%;
      margin: 0px 0px 0px -13px;
    }
    .m0-spacing {
      margin: 10px 0px;
    }
    .unit-calc {
      margin: 10px 0px 10px 0px;
    }
    .custom-product-card {
      padding: 32px 15px !important;
    }

    .unit-area {
      cursor: pointer;
      padding: 5px 0px 0px 0px;
    }
    .units {
      text-align: center;
      margin: 0px 0px 10px 22px;
    }
    .dosessection {
      margin: 4px 0px 0px 0px;
    }
  }
  .m0 {
    margin: 10px 0px;
  }

  .show-more {
    color: #0057a6;
  }
  .display-type {
    display: inline;
  }
  .productupdate-p {
    word-break: break-word;
  }
}
