:root {
  --lib-base-font-size: 16px;
  --lib-container-width: 1280px;
}

$size-none: 0;
$size-hairline: 1px;
$size-border: 0.1em;
$size-xxs: 0.5rem;
$size-xs: 0.75rem;
$size-s: 0.875rem;
$size-n: 1rem;
$size-l: 1.125rem;
$size-xl: 2rem;
$size-hero: 2.5rem;
$size-xl: 2em;
$size-xxl: 4em;
$size-s-px: 11px;

$radius-n: 4px;

$color-brand-darkest: rgb(35, 0, 76);
$color-brand-darker: rgb(60, 33, 123);
$color-brand-dark: rgb(87, 24, 176);
$color-brand-base: rgb(122, 0, 230);
$color-brand-light: rgb(137, 102, 219);
$color-brand-lighter: rgb(179, 168, 230);
$color-brand-lightest: rgb(244, 242, 246);

$color-neutral-white: #ffffff;
$color-neutral-50: #f5f5f5;
$color-neutral-100: #e4e4e4;
$color-neutral-200: #c9c9c9;
$color-neutral-300: #aeaeae;
$color-neutral-400: #939393;
$color-neutral-500: #757575;
$color-neutral-600: #5d5d5d;
$color-neutral-700: #434343;
$color-neutral-800: #282828;
$color-neutral-900: #0d0d0d;
$color-neutral-black: rgb(0, 0, 0);
