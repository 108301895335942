@use '../../../../library/01-settings/settings.breakpoints';
.patientEducation-vertical,
.patientEducation-Horizontal {
  margin-top: 40px;
  margin-bottom: 40px;
}
.patientEducation-vertical {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  .menu-content {
    p {
      color: var(--lib-color-brand-base);
    }
  }
}
@include md() {
  .patientEducation-vertical {
    width: 25%;
    max-width: 25%;
  }
  .patientEducation-Horizontal {
    padding-left: 0;
    padding-right: 0;
  }
}
.patientEducation-vertical {
  width: 25%;
  background-color: white;
  float: left;
  min-height: 450px !important;
  border-right: 1px solid var(--lib-color-neutral-200);
  min-width: 25%;
  display: flex;
  flex-direction: column;
  flex-basis: 38%;
  flex: 0;
  margin-right: 2.5%;
}
.patientEducation-vertical .row .menu-content {
  width: 100%;
}

.patientEducation-Horizontal {
  background-color: white;
  min-height: 450px !important;
  display: block;
  flex-direction: column;
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
}
.patientEducation-vertical .menu-content .list {
  margin-top: 20px;
}
cx-page-layout .sanofiResourcesPageTemplate {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .patientEducation-Horizontal {
    // width: 75%;
    background-color: white;
    min-height: 300px;
  }

  .patientEducation-vertical {
    width: 16% !important;
    background-color: transparent !important;
    padding-left: 0px !important;
    float: left !important;
    min-height: 20px !important;
    border-right: 1px solidvar(--lib-color-neutral-200);
    // margin: 0px 0px 0px 29px;
  }

  .sanofiResourcesPageTemplate {
    display: block !important;
    flex-direction: column;
  }
}

@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
  .sanofiResourcesPageTemplate {
    display: flex;
    flex-direction: column;
  }
  .dropdown-list {
    display: block;
  }
  .patientEducation-Horizontal {
    width: 100%;
    background-color: white;
    min-height: 450px !important;
  }

  .patientEducation-vertical {
    //  display: none;
    width: 100%;
    background-color: transparent;
    float: left;
    min-height: 20px !important;
  }
}
