// $skipComponentStyles: (cx-page-layout);

%ProductListPageTemplate,
%ProductGridPageTemplate {
  @extend %ProductListPageTemplate, %ProductGridPageTemplate !optional;

  @media screen and (min-width: 736px) and (max-width: 911px) {
    flex-direction: row !important;
  }

  @media screen and (min-width: 300px) and (max-width: 730px) {
    flex-direction: column !important;
  }
}

.slick-slide .unit-area img {
  display: inline-block;
}
@media screen and (min-width: 926px) {
  .slick-slider .unit-calc .units {
    width: 53% !important;
    margin: 10px 0;
  }
  .slick-slider .unit-calc .price-details {
    width: 47% !important;
    margin: 10px 0 0;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) {
  .slick-slider .unit-calc .units {
    width: 66% !important;
    margin: 0 0 10px 13px !important;
  }
}

@media screen and (min-width: 992px) {
  .custom-card-cta {
    min-height: 60px;
  }
}
