@use "../01-settings/settings.fonts" as *;
@use "../01-settings/settings.variables" as *;

.c-text {
  font-family: var(--lib-font-family-sans);
}

.text-fixed-eyebrow {
  font-family: var(--lib-font-family-sans);
  font-weight: bold;
  line-height: 150%;
  font-size: $size-s;
  letter-spacing: 0px;
}

.text-fixed-medium {
  font-family: var(--lib-font-family-sans);
  font-weight: normal;
  line-height: 150%;
  font-size: $size-n;
  letter-spacing: 0px;
}
.text-xl {
  font-size: 1.3rem;
  font-family: var(--lib-font-family-sans);
  font-weight: normal;
  line-height: 150%;
  letter-spacing: 0px;
}
.text-fixed-legal-copy {
  font-family: var(--lib-font-family-sans);
  font-weight: normal;
  line-height: 150%;
  font-size: $size-n;
  letter-spacing: 0px;
}

.color {
  &-primary {
    color: var(--lib-color-brand-base);
  }
  &-secondary {
    color: var(--lib-color-brand-light);
  }
  &-grey-light {
    color: var(--lib-color-neutral-400);
  }
  &-grey {
    color: var(--lib-color-neutral-500);
  }
  &-grey-dark {
    color: var(--lib-color-neutral-600);
  }
  &-grey-darker {
    color: var(--lib-color-neutral-800);
  }
  &-normal-content {
    color: var(--lib-color-neutral-900);
  }
  &-black {
    color: var(--lib-color-neutral-black);
  }
  &-primary {
    color: var(--lib-color-brand-base);
  }
  &-secondary {
    color: var(--lib-color-brand-light);
  }
  &-grey-light {
    color: var(--lib-color-neutral-400);
  }
  &-grey {
    color: var(--lib-color-neutral-500);
  }
  &-grey-dark {
    color: var(--lib-color-neutral-600);
  }
  &-grey-darker {
    color: var(--lib-color-neutral-800);
  }
  &-black {
    color: var(--lib-color-neutral-black);
  }
  &-error {
    color: var(--lib-color-error-red);
  }
  &-confirm {
    color: var(--lib-color-confirmation);
  }
}

.font-n {
  font-size: $size-n;
}

.font-bold {
  font-weight: bold;
}
