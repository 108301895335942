@use "../01-settings/settings.colours" as *;
@use "../01-settings/settings.fonts" as *;
@use "../01-settings/settings.variables" as *;
@use "../02-tools/tools.functions" as *;

.c-pagination {
  &-btns,
  a,
  a:first-child,
  a:last-child {
    width: rem-calc(44px);
    height: rem-calc(44px);
    background-color: transparent;
    color: var(--lib-color-brand-base);
    border: none;
    font-size: rem-calc(20px);
    border-radius: 22px;
    cursor: pointer;
    &:hover {
      background-color: var(--lib-color-brand-dark);
      color: var(--lib-color-neutral-white);
    }
    &.disabled {
      color: var(--lib-color-neutral-300);
    }
  }
  &-btns :disabled,
  &-btns[disabled] {
    color: var(--lib-color-neutral-300);
    &:hover {
      cursor: auto;
      background-color: transparent;
    }
  }
  a,
  a:first-child,
  a:last-child {
    position: relative;
    font-size: 0px;
    &.previous {
      &::after {
        content: "\f053";
        position: absolute;
        top: 9px;
        font-size: 1.25rem;
        font-family: var(--fa-style-family, "Font Awesome 6 Free");
        font-weight: var(--fa-style, 900);
      }
    }
    &.next {
      &::after {
        content: "\f054";
        position: absolute;
        top: 9px;
        font-size: 1.25rem;
        font-family: var(--fa-style-family, "Font Awesome 6 Free");
        font-weight: var(--fa-style, 900);
      }
    }
  }
}
