@use "../01-settings/settings.breakpoints" as *;
@use "../01-settings/settings.variables" as *;
@use "../02-tools/tools.functions" as *;

.SearchResultsGridPageTemplate .BottomHeaderSlot,
.ProductGridPageTemplate .BottomHeaderSlot {
  padding-bottom: 10px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--lib-color-neutral-200);
}
cx-page-layout.SearchResultsGridPageTemplate,
cx-page-layout.ProductGridPageTemplate {
  display: flex;
  flex-wrap: wrap;
  max-width: none;
  padding-left: 15px;
  padding-right: 15px;

  .ProductLeftRefinements,
  .ProductGridSlot,
  .SearchResultsGridSlot {
    display: block;
    padding: 0;
  }
  // h3,
  // h4,
  cx-facet {
    display: none;
  }
  .ng-select {
    min-width: 100%;
  }

  @include md {
    .ProductLeftRefinements {
      max-width: 25%;
      flex: 0 0 25%;
      box-shadow: var(--lib-box-shadow-spread-distance);
      padding: 1.5rem 1rem;
    }
    .ProductGridSlot,
    .SearchResultsGridSlot {
      max-width: 75%;
      flex: 0 0 75%;
      .productList {
        padding-left: 20px;
      }
    }
    h3,
    h4,
    cx-facet {
      display: block;
    }
    #refineBtn {
      display: none;
    }
  }
  @include lg {
    .ProductLeftRefinements {
      max-width: 25%;
      flex: 0 0 25%;
      box-shadow: var(--lib-box-shadow-spread-distance);
      padding: 1.5rem 1rem;
    }
    .ProductGridSlot,
    .SearchResultsGridSlot {
      max-width: 75%;
      flex: 0 0 75%;
    }
  }
  @include xl {
    padding-left: 0;
    padding-right: 0;
  }
}

.modal-dialog.refinement-modal {
  width: 100vw;
  height: 100vh;
  .heading {
    color: var(--lib-color-neutral-black);
  }
}

@include md {
  .SearchResultsGridPageTemplate
    .SearchResultsGridSlot
    .product-page
    .main
    .content-right,
  .ProductGridPageTemplate .ProductGridSlot .product-page .main .content-right {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 50% 50%;
    grid-gap: 3px;
  }
}
@include xl {
  .SearchResultsGridPageTemplate
    .SearchResultsGridSlot
    .product-page
    .main
    .content-right,
  .ProductGridPageTemplate .ProductGridSlot .product-page .main .content-right {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 33.3333% 33.3333% 33.3333%;
    grid-gap: 3px;
  }
}

cx-page-layout.ProductGridPageTemplate {
  @include md {
    flex-direction: row;
  }
}

cx-storefront .BottomHeaderSlot {
  @include lg {
    // margin-top: 180px;
  }
}
