@use "../01-settings/settings.breakpoints" as *;

.HomePageTemplate cx-page-slot.Section2B {
    cx-banner {
        min-height: rem-calc(244px);
        height: auto;
        margin-bottom: 1rem;
        position: relative;

        @include md {
            position: absolute;
        }
    }

    vsca-cx-login-form {
        position: relative;
        display: block;

        @include md {
            display: flex;
            justify-content: flex-end;
        }
    }
}

cx-page-layout.LoginPageTemplate {
    vsca-cx-login-form {
        max-width: none;

        @include md {
            .c-login-form {
                margin: 0 auto;
            }
        }
    }
}
