@use "../01-settings/settings.variables" as *;
@use "../02-tools/tools.functions" as *;

.c-form {
  &-label {
    color: var(--lib-color-form-label);
  }

  &-input {
    width: 100%;
    height: rem-calc(45px);
    border-radius: rem-calc(4px);
    box-shadow: inset rem-calc(0 1px 5px 0) var(--lib-color-form-input-border);
    border: solid rem-calc(1px) var(--lib-color-form-input-shadow);
    background-color: var(--lib-color-white);
    display: inline-block;
    padding: rem-calc(10px 12px);
    font-size: rem-calc(14px);
    margin: rem-calc(5px 0);
  }

  &-container {
    margin: rem-calc(0px 0px 10px 0px);
  }
}

.c-form-title {
  font-weight: bold;
  color: var(--lib-color-neutral-black);
  letter-spacing: normal;
}

.c-form-copy {
  font-weight: normal;
  color: var(--lib-color-neutral-black);
  letter-spacing: normal;
  font-size: rem-calc(20px);
}

.c-text-input .c-form-label {
  font-size: rem-calc(18px);
}
