$normal: rgba(117, 167, 83, 1);
$low: rgba(232, 140, 34, 1);
$limited: rgba(203, 58, 62, 1);

.c-round-chip {
  height: 25px;
  width: 25px;
  border-radius: 25px;
  &-normal {
    background-color: $normal;
  }
  &-low {
    background-color: $low;
  }
  &-limited,
  &-ended {
    background-color: $limited;
  }
}

.c-stock-level {
  display: flex;
  align-items: center;
}
