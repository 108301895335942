@use '../01-settings/settings.fonts' as *;
@use '../01-settings/settings.variables' as *;

cx-page-layout.HomePageTemplate cx-page-slot.Section2A .home-banner-left h2{
    font-family: var(--lib-font-family-sans);
    font-weight: bold;
    line-height: 125%;
    font-size: $size-hero;
    letter-spacing: $size-none;
    margin-bottom: 20px;
}