@use "../01-settings/settings.breakpoints" as *;
@use '../01-settings/settings.colours' as *;
@use '../01-settings/settings.variables' as *;
@use '../02-tools/tools.functions' as *;

.c-prod-details {
    & a h2 {
        color: var(--lib-color-brand-base);
        &:hover,
        &:active {
            color: var(--lib-color-brand-dark);
        }
        &:focus {
            color: var(--lib-color-brand-base);
            outline: none;
        }
    }

    aside {
        & .aside-section {
            padding: rem-calc(32px);

            &:first-child {
                border-top: none;
            }
        }

        & .savings-total {
            text-align: center;

            button {
                width: 100%;
            }

            h3 {
                color: var(--lib-color-neutral-800);
            }
        }
    }

    & .pdp-list-data {
        border-top: 1px solid var(--lib-color-neutral-200);
        padding-top: rem-calc(25px);

        &>div {
            &:first-child,
            &:last-child {
                margin-bottom: $size-xl;
            }
        }

        & .product-price {

            span {
                color: var(--lib-color-neutral-800);
                margin-bottom: rem-calc(5px);
            }
        }
        
    }
}
