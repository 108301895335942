@use "../01-settings/settings.colours" as *;
@use "../01-settings/settings.fonts" as *;
@use "../01-settings/settings.variables" as *;
@use "../02-tools/tools.functions" as *;

.c-text-input {
  input {
    background-color: var(--lib-color-neutral-50);
    border-radius: $radius-n;
    width: 100%;
    font-family: var(--lib-font-family-sans);
    font-size: $size-n;
    font-weight: normal;
    line-height: 150%;
    letter-spacing: $size-none;
    color: var(--lib-color-neutral-900);
    display: flex;
    position: relative;
    margin: rem-calc(4px 0px);
    padding: rem-calc(12px 16px);
    border: none;
    -webkit-box-flex: 1;
    flex-grow: 1;
    appearance: none;
    box-shadow: none;
    box-sizing: border-box;

    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:focus-visible {
      outline: none;
      box-shadow: var(--lib-color-brand-lighter) 0px 0px 0px 4px;
      &--danger {
        box-shadow: #d72b3f 0px 0px 0px 4px;
      }
    }

    &::placeholder {
      color: var(--lib-color-neutral-600);
    }
    &.focus-visible {
      outline: none;
      box-shadow: var(--lib-color-brand-lighter) 0px 0px 0px 2px;
      &--danger {
        box-shadow: #d72b3f 0px 0px 0px 2px;
      }
      &:not(.invalidClass) {
        box-shadow: none;
      }
    }
  }

  &--with-icon-right {
    position: relative;

    input {
      padding-right: rem-calc(48px);
    }

    i {
      color: var(--lib-color-neutral-600);
      position: absolute;
      top: rem-calc(16px);
      right: rem-calc(16px);
      font-size: rem-calc(16px);

      .fa-chevron-down {
        font-size: rem-calc(14px);
      }
    }
  }

  &--with-icon-left {
    position: relative;

    input {
      padding-left: rem-calc(48px);
    }

    i {
      color: var(--lib-color-neutral-600);
      position: absolute;
      z-index: 1;
      top: rem-calc(16px);
      left: rem-calc(16px);
      font-size: rem-calc(16px);

      .fa-chevron-down {
        font-size: rem-calc(14px);
      }
    }

    button {
      color: var(--lib-color-neutral-600);
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      padding: rem-calc(16px);
      font-size: rem-calc(16px);
      border: none;
      background: none;
    }
  }

  label {
    color: var(--lib-color-neutral-900);
    font-family: var(--lib-font-family-sans);
    font-weight: normal;
    font-size: $size-n;
    margin: $size-none;
    letter-spacing: $size-none;
    user-select: none;
    /* because .user-form form label has all: unset; */
    line-height: 150% !important;
  }

  cx-form-errors {
    font-family: var(--lib-font-family-sans);
    font-weight: normal;
    line-height: 150%;
    font-size: $size-s;
    letter-spacing: $size-none;
    margin: $size-none;
    padding: $size-none;
    color: var(--lib-color-error-red);

    p {
      position: relative;
      padding-left: rem-calc(22px);

      &::before {
        border-style: solid;
        border-width: rem-calc(0px 7px 12px 7px);
        border-color: transparent transparent var(--lib-color-error-red)
          transparent;
        border-radius: initial;
        background-color: unset;
        height: rem-calc(12px);
        width: rem-calc(14px);
        top: -2px;
      }

      &::after {
        font-size: $size-xs;
        background-color: unset;
        height: rem-calc(12px);
        width: rem-calc(14px);
        line-height: rem-calc(14px);
      }
    }
  }
  ng-select {
    float: left;
    min-width: 100%;
  }
  .custom-control {
    padding: rem-calc(0 15px);
    &-label::before {
      border: 1px solid var(--lib-color-brand-base);
    }
  }
  .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: var(--lib-color-brand-base);
  }
}
.password-description {
  font-size: $size-n;
  line-height: 1.5;
  ul {
    padding-left: 20px;
  }
}
form.ng-submitted .c-text-input input.ng-invalid {
  box-shadow: var(--lib-color-error-red) 0px 0px 0px 1px;
}
