@use '../01-settings/settings.colours' as *;
@use '../01-settings/settings.fonts' as *;
@use '../01-settings/settings.variables' as *;
@use '../02-tools/tools.functions' as *;


.ad-product-details,
.c-quick-order-widget {
  h3 {
    font-family: var(--lib-font-family-sans);
    color: var(--lib-color-neutral-900);
    font-weight: bold;
    align-self: stretch;
    flex-grow: 1;
    font-size: rem-calc(24px);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
  }

  h6 {
    color: var(--lib-color-neutral-900);
    font-weight: bold;
    font-size: rem-calc(18px);
    line-height: 1.25;
    margin-top: rem-calc(21px);
    margin-bottom: rem-calc(16px);
  }

  p {
    margin: 0;
  }
}

app-quick-order cx-searchbox {
  width: 100%!important;
  max-width: initial;
  min-width: initial;

  & label.searchbox {
    all: initial;

    & input {
      font-family: var(--lib-font-family-sans);
      font-size: $size-n;
      font-weight: normal;
      line-height: 150%;
      letter-spacing: $size-none;
      color: var(--lib-color-neutral-900);
      border-radius: $radius-n;
      background-color: var(--lib-color-neutral-50);
      display: flex;
      position: relative;
      border: none;
      -webkit-box-flex: 1;
      flex-grow: 1;
      appearance: none;
      box-shadow: none;
      box-sizing: border-box;
      width: 100%;
      min-width: initial;
      height: auto;
      margin: 0;
      padding: rem-calc(12px 16px 12px 48px);

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:focus-visible {
        outline: none;
        box-shadow: var(--lib-color-brand-lighter) 0px 0px 0px 4px;
      }

      &::placeholder {
        color: var(--lib-color-neutral-600);
      }
    }

    .search-icon {
      position: absolute;
      top: 4px;
      left: 0;
      z-index: 20;
      color: var(--lib-color-neutral-600);
      padding: rem-calc(12px 16px);
    }
  }

  #quickOrder.results {
    border-radius: $radius-n;
    box-shadow: var(--lib-box-shadow-spread-distance);

    &:empty {
      display: none;
    }

    li {
      a {
        font-family: var(--lib-font-family-sans);
        color: var(--lib-color-brand-base);
        text-decoration: underline;
        line-height: 1.5;
        font-size: rem-calc(16px);
        padding: rem-calc(12px 16px);
        letter-spacing: normal;
        font-weight: normal;

        &.resultnot-found {
          color: var(--lib-color-neutral-900);
        }
      }
    }
  }
}
