@use "../01-settings/settings.variables" as *;
@use "../01-settings/settings.breakpoints" as *;
.u-border-top-grey {
  border-top: 1px solid var(--lib-color-neutral-200);
}

.u-border-bottom-grey {
  border-bottom: 1px solid var(--lib-color-neutral-200);
}

.u-border-bottom-black {
  border-bottom: 1px solid var(--lib-color-neutral-black);
}

.u-border-right-grey {
  @include sm {
    border-right: 1px solid var(--lib-color-neutral-200);
  }
}
.u-border-left-grey {
  @include sm {
    border-left: 1px solid var(--lib-color-neutral-200);
  }
}
