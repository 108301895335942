@use "../01-settings/settings.variables" as *;

.terms-Conditions p {
    line-height: 1.5;
    margin-bottom: 0;
    a {
        color: var(--lib-color-brand-base);
        margin-left: 3px;
        &:hover {
            color: var(--lib-color-brand-base-dark);
        }
    }
}
