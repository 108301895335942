app-cms-paragraph .custom-cms-paragraph {
  h3 {
    font-size: 2.25rem;
    font-family: var(--lib-font-family-sans);
    font-weight: bold;
    line-height: 125%;
    letter-spacing: 0px;
    margin-bottom: 40px;
  }
  a {
    color: var(--lib-color-brand-base);
    text-underline-offset: 3px;
    font-family: var(--lib-font-family-sans);
    font-weight: normal;
  }
  .sub-text {
    padding-left: 15px;
    padding-right: 15px;
  }
}
