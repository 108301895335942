@use "../01-settings/settings.colours" as *;
@use "../01-settings/settings.fonts" as *;
@use "../01-settings/settings.variables" as *;
@use "../02-tools/tools.functions" as *;

.c-button {
  cursor: pointer;
  font-size: $size-n;
  font-weight: normal;
  text-decoration: none;
  line-height: 100%;
  font-family: var(--lib-font-family-sans);
  letter-spacing: 0px;
  width: fit-content;
  padding: rem-calc(0px 24px);
  height: rem-calc(44px);
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  vertical-align: middle;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: none;
  border-radius: $radius-n;
  min-width: rem-calc(100px);
  & i {
    margin-right: 0.5rem;
    margin-top: rem-calc(2px);
  }

  &--with-icon {
    justify-content: space-between;
    gap: $size-xxs;

    i {
      margin: 0;
    }
  }

  &--primary {
    color: var(--lib-color-neutral-white);
    background: var(--lib-color-brand-base);

    &:hover {
      background: var(--lib-color-brand-dark);
    }

    &:focus {
      outline: none;
    }

    &:active {
      background: var(--lib-color-brand-darker);
    }

    &:disabled {
      background: var(--lib-color-neutral-300);
      cursor: not-allowed;
    }

    &--inverse {
      color: var(--lib-color-brand-base);
      background: var(--lib-color-neutral-white);

      &:hover {
        color: var(--lib-color-brand-dark);
        background: var(--lib-color-brand-lightest);
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:active {
        color: var(--lib-color-neutral-white);
        background: var(--lib-color-brand-darker);
      }

      &:disabled {
        cursor: not-allowed;
        background: var(--lib-color-neutral-300);
        color: var(--lib-color-neutral-white);
      }
    }
  }

  &--secondary {
    color: var(--lib-color-brand-dark);
    border-color: var(--lib-color-brand-dark);
    border-style: solid;
    border-width: 1.5px;
    background-color: transparent;

    &:hover {
      background-color: var(--lib-color-brand-lightest);
    }

    &:focus {
      outline: none;
      box-shadow: none;
      background-color: var(--lib-color-brand-lightest);
    }

    &:active {
      color: var(--lib-color-neutral-white);
      border-color: var(--lib-color-brand-darker);
      background-color: var(--lib-color-brand-darker);
    }

    &:disabled {
      cursor: not-allowed;
      color: var(--lib-color-neutral-300);
      border-color: var(--lib-color-neutral-300);
    }

    &--inverse {
      color: var(--lib-color-neutral-white);
      border-color: var(--lib-color-neutral-white);
      border-style: solid;
      border-width: 1.5px;
      background-color: transparent;

      &:hover {
        color: var(--lib-color-brand-dark);
        background-color: var(--lib-color-brand-lightest);
        border-color: var(--lib-color-brand-lightest);
      }

      &:focus {
        outline: none;
        box-shadow: none;
        color: var(--lib-color-brand-base);
        background-color: var(--lib-color-brand-lightest);
        border-color: var(--lib-color-brand-lightest);
      }

      &:active {
        color: var(--lib-color-neutral-white);
        border-color: var(--lib-color-brand-darker);
        background-color: var(--lib-color-brand-darker);
      }

      &:disabled {
        cursor: not-allowed;
        color: var(--lib-color-neutral-300);
        border-color: var(--lib-color-neutral-300);
      }
    }
  }

  &--tertiary {
    color: var(--lib-color-brand-base);
    background-color: transparent;

    &:hover {
      color: var(--lib-color-neutral-white);
      background-color: var(--lib-color-brand-dark);
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:active {
      color: var(--lib-color-neutral-white);
      background-color: var(--lib-color-brand-darker);
    }

    &:disabled {
      cursor: not-allowed;
      color: var(--lib-color-neutral-300);
    }

    &--inverse {
      color: var(--lib-color-neutral-white);
      background-color: transparent;

      &:hover {
        color: var(--lib-color-brand-dark);
        background-color: var(--lib-color-brand-lightest);
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:active {
        color: var(--lib-color-neutral-white);
        background-color: var(--lib-color-brand-darker);
      }

      &:disabled {
        cursor: not-allowed;
        color: var(--lib-color-neutral-300);
      }
    }
  }

  &--lg {
    min-height: rem-calc(52px);
  }

  &--link {
    color: var(--lib-color-neutral-black);
    background-color: transparent;
    text-decoration: underline;
    font-size: rem-calc(18px);

    &:hover {
      color: var(--lib-color-neutral-900);
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:active {
      color: var(--lib-color-neutral-900);
    }

    &:disabled {
      cursor: not-allowed;
      color: var(--lib-color-neutral-300);
    }
  }

  &--link-primary {
    color: var(--lib-color-brand-base);
    display: inline;
    font-size: inherit;
    height: auto;
    padding: inherit;
    background-color: transparent;
    text-decoration: underline;

    &:hover {
      color: var(--lib-color-brand-dark);
    }

    &:focus {
      color: var(--lib-color-brand-base);
      outline: none;
      box-shadow: none;
    }

    &:active {
      color: var(--lib-color-brand-base);
    }

    &:disabled {
      cursor: not-allowed;
      color: var(--lib-color-neutral-300);
    }
  }

  &--link-primary-lg {
    color: var(--lib-color-brand-base);
    display: inline;
    background-color: transparent;
    text-decoration: underline;

    &:hover {
      color: var(--lib-color-brand-dark);
    }

    &:focus {
      color: var(--lib-color-brand-base);
      outline: none;
      box-shadow: none;
    }

    &:active {
      color: var(--lib-color-brand-base);
    }

    &:disabled {
      cursor: not-allowed;
      color: var(--lib-color-neutral-300);
    }
  }

  &--chips {
    height: auto;
    padding: rem-calc(8px 16px);
    border-radius: 100px;
    border: solid 2px var(--lib-color-neutral-700);
    color: var(--lib-color-neutral-700);
    background-color: transparent;

    &:hover {
      border: solid 2px var(--lib-color-neutral-500);
      color: var(--lib-color-neutral-500);
      background-color: transparent;
    }

    &:focus {
      border: solid 2px var(--lib-color-neutral-700);
      color: var(--lib-color-neutral-700);
      background-color: transparent;
      outline: none;
      box-shadow: none;
    }

    &:active {
      border: solid 2px var(--lib-color-neutral-900);
      color: var(--lib-color-neutral-900);
      background-color: transparent;
    }
  }
}

.mob-login {
  a {
    text-decoration: none;
  }

  .c-button {
    width: 100%;
  }
}

.c-popover {
  display: flex;
  justify-content: space-between;

  .c-button {
    width: 47%;
  }
}

.c-count-change-btn {
  width: rem-calc(24px);
  height: rem-calc(24px);
  padding-top: rem-calc(2px);
  text-decoration: none;
  display: flex;
  align-items: start;
  justify-content: center;
  vertical-align: middle;
  border-radius: 999px;
  border-style: solid;
  border-width: 2px;
  background-color: var(--lib-color-neutral-white);

  color: var(--lib-color-brand-base);
  border-color: var(--lib-color-brand-base);

  &:hover,
  &:active {
    color: var(--lib-color-brand-dark);
    border-color: var(--lib-color-brand-dark);
  }

  &:focus {
    color: var(--lib-color-brand-dark);
    border-color: var(--lib-color-brand-dark);
    outline: none;
  }

  &.disabled {
    color: var(--lib-color-neutral-300);
    border-color: var(--lib-color-neutral-300);
    pointer-events: none;
  }
}
