@use "../02-tools/tools.functions" as *;
@use "../01-settings/settings.breakpoints" as *;

.c-feedback {
    position: fixed;
    bottom: rem-calc(50px);
    right: rem-calc(-26px);
    background-color: var(--lib-color-brand-base);
    padding: rem-calc(20px);
    cursor: pointer;
    transform: rotate(-90deg);
    border-top-left-radius: rem-calc(5px);
    border-top-right-radius: rem-calc(5px);
    z-index: 100;
    margin-bottom: 0;
    height: rem-calc(60px);
    width: rem-calc(110px);
}
.feedback-modal {
    input[type="text"],textarea {
        border: 1px solid var(--lib-color-neutral-100);
        background-color: var(--lib-color-neutral-white);
        color: var(--lib-color-neutral-600);
        &:focus {
            border: 1px solid var(--lib-color-neutral-50);
            background-color: var(--lib-color-neutral-white);
            outline: none;
        }
        &::placeholder {
            color: var(--lib-color-neutral-400);
        }
       
    }
    input[type="text"].email {
        background-color: var(--lib-color-neutral-50);
    }
    input {
        min-width: calc(100vw - 48px);
        @include md {
            min-width: rem-calc(400px);
        }
    }

}