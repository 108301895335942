@use "../01-settings/settings.breakpoints" as *;
@use "../01-settings/settings.variables" as *;
@use "../02-tools/tools.functions" as *;

app-faq {
  border-right: none;

  @include md {
    border-right: 1px solid var(--lib-color-neutral-200);
    margin-top: 1.4rem;
  }
}
app-faq-title {
  .text-fixed-medium {
    padding-right: 15px;
  }
  @include md {
    .text-fixed-medium {
      padding-right: 3rem;
    }
  }
}
.faq-container {
  display: flex;
  align-items: stretch;
  background-color: #fff;
}
.child-container-one {
  flex-grow: 6;
}
.child-container-two {
  flex-grow: 4;
}

cx-page-layout.ContentPage1Template {
  margin: 0px 0px 40px 0px;
  display: inline-flex;
  flex-direction: row;
  max-width: 100%;
  width: 100%;
  padding: 0px;
}

cx-page-layout cx-page-slot.FAQ-left {
  min-height: 1px;
  width: 66%;
  flex: 0 0 66.66666667%;
}

cx-page-layout cx-page-slot.ContentSlotFAQ {
  flex: auto;
  background: white;
  padding-left: 20px;
  max-width: 32%;
}

@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
  cx-page-layout cx-page-slot.FAQ-left {
    width: 100%;
    min-width: 100%;
  }
  cx-page-layout cx-page-slot.ContentSlotFAQ {
    min-width: 100%;
    width: 100%;
  }
  cx-page-layout.ContentPage1Template {
    display: block;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  cx-page-layout cx-page-slot.FAQ-left {
    width: 66%;
    min-width: 66%;
  }
  cx-page-layout cx-page-slot.ContentSlotFAQ {
    min-width: 32%;
  }
  cx-page-layout.ContentPage1Template {
    margin: 0px 0px 40px 0px;
    display: inline-flex;
    flex-direction: row;
    max-width: 100%;
    width: 100%;
    padding: 0px;
  }
}

.order-info p {
  padding: 20px 0px 0px 0px;
}

// app-faq-title .left-section {
//   background-color: #ffffff;
//   padding: 30px 20px 0;
//   border-right: 4px solid #edf6fb;
// }
.faq p {
  margin-bottom: 0;
  font-size: rem-calc(16px);
  font-family: var(--lib-font-family-sans);
  line-height: 150%;
  padding-left: 15px;
}

.faq-panel-header {
  margin-top: 35px;
  font-family: "Poppins-Medium", sans-serif;
  margin-bottom: 0;
  h3 {
    margin-bottom: 0;
    color: #5b646e;
    font-size: 20px;
  }
}
.child-container-one {
  padding: 0px 15px 30px;
  width: 42%;
  @include md {
    padding: 0px 3rem 30px 15px;
  }
}

.all-child-btn,
.item-child-container,
.item-child-answer {
  display: none;
}
.show {
  display: block !important;
}
.all-child-btn {
  margin-bottom: 10px;
  padding: 0;
  &.show {
    display: inline-block !important;
  }
}
.toggle-item {
  text-align: right;
}
.faq-item {
  button {
    border: none;
    background-color: transparent;
  }
  //   h5 {
  //     font-size: 12px;
  //     padding-right: 15px;
  //     position: relative;
  //     border-bottom: none;
  //     color: #5b646e;
  //     font-family: "NotoSans", sans-serif;
  //     font-weight: bold;
  //     margin-bottom: 0;
  //   }
}

.item-child {
  border-top: 1px solid var(--lib-color-neutral-200);
}
.item-child-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}
.item-child-answer {
  border-top: 1px solid var(--lib-color-neutral-200);
  padding: 10px 0 15px;
}
.all-child-btn {
  font-size: rem-calc(16px);
  color: var(--lib-color-brand-base);
}

.faq-item button.item-btn,
.toggle-item button.child-btn {
  background-image: url(../icons/plus.svg);
  width: 16px;
  height: 16px;
  background-size: 16px;
  display: block;
  cursor: pointer;
  background-repeat: no-repeat;
  &.active {
    background-image: url(../icons/minus.svg);
  }
}

.item-child-question {
  h5 {
    width: 90%;
  }
  .toggle-item {
    text-align: right;
  }
}

.faq-item .item-child-answer {
  a {
    color: var(--lib-color-brand-base);
    word-wrap: break-word;
    font-size: 1rem;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
