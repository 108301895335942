@use "../01-settings/settings.breakpoints" as *;
@use "../01-settings/settings.fonts" as *;
@use "../02-tools/tools.functions" as *;

.c-heading {
  font-family: var(--lib-font-family-sans);
  font-weight: bold;
  line-height: 125%;
  letter-spacing: 0px;

  &-h1 {
    font-size: 2.25rem;

    @include md {
      font-size: 2.5rem;
    }

    @include xl {
      font-size: 3rem;
    }
  }

  &-h2 {
    font-size: 1.75rem;

    @include md {
      font-size: 2rem;
    }

    @include xl {
      font-size: 2.25rem;
    }
  }

  &-h3 {
    font-size: 1.25rem;

    @include md {
      font-size: 1.5rem;
    }

    @include xl {
      font-size: 1.5rem;
    }
  }

  &-h4 {
    font-size: 1.125rem;
  }

  &-h5 {
    font-size: 1rem;
  }
  &-36 {
    font-size: rem-calc(36px);
  }
}
