@use "../01-settings/settings.colours" as *;
@use "../01-settings/settings.fonts" as *;
@use "../01-settings/settings.variables" as *;
@use "../02-tools/tools.functions" as *;
.c-select ng-select.ng-select,
ng-select.ng-select.c-select {
  &:focus {
    outline: none;
  }
  .ng-arrow-wrapper,
  .ng-select.ng-select-single .ng-arrow-wrapper {
    padding: 0;

    .ng-arrow {
      color: var(--lib-color-neutral-600);
      border-color: var(--lib-color-neutral-600) !important;
      top: -3px;
      padding: 4px;
    }
  }

  .ng-select-container {
    margin: 0 !important;
    height: rem-calc(48px) !important;
    box-shadow: none;
    border: none;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: rem-calc(12px 16px);
    gap: $size-n;
    border-radius: $radius-n;
    background-color: var(--lib-color-neutral-50);

    .ng-value-container {
      padding: 0;
      font-family: var(--lib-font-family-sans);
      font-size: $size-n;
      line-height: 1.5;

      .ng-value {
        color: var(--lib-color-neutral-900);
      }
    }
  }

  .ng-dropdown-panel {
    border: none;
    box-shadow: none;

    &.ng-select-top {
      margin-bottom: rem-calc(2px);
    }
    &.ng-select-bottom {
      margin-top: rem-calc(2px);
    }

    .ng-dropdown-panel-items {
      border-radius: $radius-n;
      box-shadow: var(--lib-box-shadow-spread-distance);
      padding: 0;

      div {
        &:empty {
          display: none;
        }

        .ng-option {
          font-family: var(--lib-font-family-sans);
          text-decoration: underline;
          line-height: 1.5;
          font-size: rem-calc(16px);
          letter-spacing: normal;
          font-weight: normal;
          color: var(--lib-color-brand-base) !important;
          padding: rem-calc(12px 16px) !important;

          &.ng-option-marked {
            color: var(--lib-color-brand-dark) !important;
            background-color: var(--lib-color-neutral-50);
          }
        }
      }
    }
  }
}
