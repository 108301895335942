@use "../01-settings/settings.variables" as *;
@use "../02-tools/tools.functions" as *;

.Registration-nav {
  background: var(--lib-color-neutral-white);
}
.p0 {
  padding: 0;
}
.Registration-nav > div {
  display: inline-block;
  padding: rem-calc(24px 15px 0 15px);
  position: relative;
  text-align: left;
}
.Common-registration {
  position: relative;
  display: inline-block;
  padding: 0 0 25px 0;
}
.online-invoices .Registration-nav .Common-registration:before {
  background: none;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-left: 13px solid rgba(255, 255, 255, 0.25);
}
.Registration-nav .Common-registration:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
  border-left: 13px solid rgba(255, 255, 255, 0.25);
  right: -70px;
  top: 0px;
}

.Registration-nav .Common-registration {
  span:nth-child(1) {
    width: 21px;
    height: 21px;
    display: inline-block;
    background: rgba(255, 255, 255, 0.25);
    text-align: center;
    border-radius: 50%;
    line-height: 1.43;
    color: var(--lib-color-neutral-400);
    font-size: rem-calc(14px);
    font-family: "Poppins-Medium", sans-serif;
    border: 1px solid var(--lib-color-neutral-400);
  }
  &.Common-active span:nth-child(1) {
    border: 1px solid var(--lib-color-brand-base);
    color: var(--lib-color-brand-base);
  }
}

// .Registration-nav .Common-active span:nth-child(1) {
//   width: 21px;
//   height: 21px;
//   display: inline-block;
//   background: #fff;
//   text-align: center;
//   border-radius: 50%;
//   line-height: 1.43;
//   color: var(--lib-color-brand-base);
//   font-size: rem-calc(14px);
//   font-family: "Poppins-Medium", sans-serif;
//   border: 1px solid var(--lib-color-brand-base);
// }

.Registration-nav .Common-registration span:nth-child(2) {
  font-size: rem-calc(14px);
  display: inline-block;
  color: var(--lib-color-neutral-400);
  font-family: "Poppins-Medium", sans-serif;
  margin: 0 0 0 8px;
  font-weight: normal;
}
.Registration-nav .Common-active span:nth-child(2) {
  font-weight: bold;
  color: var(--lib-color-neutral-black);
}
// .Registration-nav .Common-registration span:nth-child(2):after {
//   content: "";
//   position: absolute;
//   width: 0;
//   height: 0;
//   border-top: 13px solid transparent;
//   border-bottom: 13px solid transparent;
//   border-left: 13px solid #549cc9;
//   top: 0px;
//   right: -67px;
// }
// .Registration-nav .Common-registration.Common-active:after {
//   content: "";
//   position: absolute;
//   border-top: 3px solid #fff;
//   left: 0px;
//   width: 100%;
//   bottom: 0;
// }
.singleHeader {
  display: none;
}
.multipleHeader {
  display: block;
}

@media only screen and (max-width: 992px) and (min-width: 767px) {
  .multipleHeader {
    display: none;
  }
  .singleHeader {
    display: block;
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .multipleHeader {
    display: none;
  }
  .singleHeader {
    display: block;
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .multipleHeader {
    display: none;
  }
  .singleHeader {
    display: block;
    margin-left: 0px;
    margin-right: 0px;
    text-align: center;
  }
}
.Registration-nav .confirmation :before {
  border: none;
}
