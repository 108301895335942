.nav-tabs {
  border: none;
  .nav-item {
    border-bottom: 1px solid var(--lib-color-neutral-200);
    cursor: pointer;
    &.nav-Item-Border {
      border-bottom: 4px solid var(--lib-color-brand-base);
    }
  }
}

.custom-control-label::before,
.custom-control-label::after {
  top: 0;
}
