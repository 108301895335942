// https://colors.artyclick.com/color-name-finder/

:root {
  --lib-background-body: default;
  --lib-color-body: default;

  --lib-background-box-title: default;
  --lib-color-box-title: default;

  --lib-color-link: default;
  --lib-color-link-hover: default;
  --lib-color-primary: default;

  --lib-color-form-title: default;
  --lib-color-form-label: default;
  --lib-color-form-input-border: default;
  --lib-color-form-input-shadow: default;

  --lib-color-brand-darkest: rgb(35, 0, 76);
  --lib-color-brand-darker: rgb(60, 33, 123);
  --lib-color-brand-dark: rgb(87, 24, 176);
  --lib-color-brand-base: rgb(122, 0, 230);
  --lib-color-brand-light: rgb(137, 102, 219);
  --lib-color-brand-lighter: rgb(179, 168, 230);
  --lib-color-brand-lightest: rgb(244, 242, 246);

  --lib-color-neutral-white: rgb(255, 255, 255);
  --lib-color-neutral-50: rgb(245, 245, 245);
  --lib-color-neutral-100: rgb(228, 228, 228);
  --lib-color-neutral-200: rgb(201, 201, 201);
  --lib-color-neutral-300: rgb(174, 174, 174);
  --lib-color-neutral-400: rgb(147, 147, 147);
  --lib-color-neutral-500: rgb(117, 117, 117);
  --lib-color-neutral-600: rgb(93, 93, 93);
  --lib-color-neutral-700: rgb(67, 67, 67);
  --lib-color-neutral-800: rgb(40, 40, 40);
  --lib-color-neutral-900: rgb(13, 13, 13);
  --lib-color-neutral-black: rgb(0, 0, 0);

  --lib-color-error-red: rgb(215, 43, 63);
  --lib-color-confirmation: #147014;
}
