#cx-active-facets-groupName {
  font-size: 1rem;
  &:first-child {
    flex: none;
    margin: 0;
    padding: 0;
  }
}
#clearAll-filters {
  display: flex;
  align-items: center;
  min-width: 102px;
  color: var(--lib-color-brand-base);
  text-decoration: underline;
  margin-left: 5px;
  &:hover {
    color: var(--lib-color-brand-dark);
  }
}
cx-active-facets {
  align-items: center;
  a {
    margin: 5px;
    height: auto;
    border-radius: 100px;
    border: solid 2px var(--lib-color-neutral-700);
    color: var(--lib-color-neutral-700);
    background-color: transparent;
    font-size: 1rem;
    font-weight: 400;
    text-decoration: none;
    line-height: 100%;
    font-family: var(--lib-font-family-sans);
    letter-spacing: 0px;
    position: relative;
    padding: 0.5rem 1rem 0.5rem 30px;
    &::before {
      position: absolute;
      content: "\f057";
      font-family: var(--fa-style-family, "Font Awesome 6 Free");
      font-weight: var(--fa-style, 900);
      left: 15px;
    }
    .cx-icon {
      display: none;
    }
    &:hover {
      border: solid 2px var(--lib-color-neutral-500);
      color: var(--lib-color-neutral-500);
      background-color: transparent;
    }
  }
}
