@use "../02-tools/tools.functions" as *;
.checkout-modal {
  .modal-dialog {
    width: auto;
    max-width: rem-calc(582px);
    margin-top: 0;
    .close,
    .close:not(:disabled):not(.disabled):hover {
      opacity: 1;
    }
  }
  .modal-header,
  .modal-body {
    padding: rem-calc(24px);
  }
  .modal-header {
    border-bottom: none;
  }
  .modal-body {
    padding-top: 0;
    padding-bottom: 0;
  }
}
