@use '../01-settings/settings.fonts' as *;
@use '../01-settings/settings.colours' as *;
@use "../01-settings/settings.breakpoints" as *;
@use '../02-tools/tools.functions' as *;

.c-widget,
.NewsAndUpdatesSlot{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: rem-calc(24px);
    padding: rem-calc(24px);
    margin-bottom: rem-calc(24px);
    background-color: var(--lib-color-neutral-white);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

    &--no-borders {
        box-shadow: none;
        padding: rem-calc(24px 0);
    }

    @media (max-width: #{$screen-sm-min}) {
        box-shadow: none;
        padding: rem-calc(24px 0);
    }

    &-title {
        padding: 0;
        margin: 0;
    }

    &-divider {
        height: 1px;
        width: 100%;
        background-color: var(--lib-color-neutral-black);
    }

    strong {
        font-weight: bold;
    }

    &>div {
        width: 100%;
    }

    &--my-account {
        &-description {
            align-self: stretch;
            flex-grow: 1;
            font-family: var(--lib-font-family-sans);
            font-size: rem-calc(18px);
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: normal;
            text-align: left;
            color: var(--lib-color-neutral-900);
        }
    }
}

.NewsAndUpdatesSlot{
    line-height: 2;

    h3 {
        font-family: var(--lib-font-family-sans);
        color: var(--lib-color-neutral-900);
        font-weight: bold;
        align-self: stretch;
        flex-grow: 1;
        font-size: 1.5rem;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: left;
        margin-bottom: 1.5rem;
    }

    li {
        overflow-wrap: anywhere;
    }
}
