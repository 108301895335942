ngb-popover-window {
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: var(--lib-color-neutral-white);
  color: var(--lib-color-neutral-900);
  top: 5px;
}
.c-popover-black-container {
  ngb-popover-window {
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: var(--lib-color-neutral-white);
    color: var(--lib-color-neutral-900);
    top: -15px !important;
    width: auto;
    &:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid var(--lib-color-neutral-black);
      background-color: transparent;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
}

.common-error {
  margin: -24px 10px 3px 0px;
  float: left;
  font-family: "NotoSans", sans-serif;
  font-size: 11px;
  color: var(--lib-color-error-red);
}
