@use "../01-settings/settings.box-shadow" as *;
@use "../01-settings/settings.colours" as *;
@use "../01-settings/settings.fonts" as *;
@use "../01-settings/settings.variables" as *;
@use "../01-settings/settings.breakpoints" as *;
@use "../02-tools/tools.functions" as *;

@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/regular";
@import "@fortawesome/fontawesome-free/scss/solid";

.common-alert-banner > section > section,
.account-overview-banner {
  display: block;
  background-color: var(--lib-color-neutral-white);
  box-shadow: var(--lib-box-shadow-spread-distance);
  position: inherit;
  width: auto;
  /* legacy styles override */
  border-bottom: none;
  margin-top: rem-calc(3px);
  margin-bottom: rem-calc(20px);

  .acc-warning-box {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 32px);
    min-height: rem-calc(72px);
    flex-direction: column;
    /* legacy styles override */
    min-height: auto;
    margin-bottom: auto;
    border: none;

    @include sm {
      width: calc(100% - 64px);
    }

    @include md {
      flex-direction: row;
      width: calc(100% - 64px);
    }

    > section.row,
    > div.row {
      display: flex;
      flex-flow: row wrap;
      margin: 0px calc(-6px);
      /* legacy styles override */
      flex-wrap: initial;

      @include sm {
        margin: 0px calc(-8px);
        width: calc(100% + 16px);
      }

      @include md {
        margin: 0px calc(-8px);
        width: calc(100% + 16px);
      }

      > section:first-child,
      > div:first-child {
        -webkit-box-flex: 0;
        flex-grow: 0;
        flex-basis: auto;
        box-sizing: border-box;
        width: calc(75%);
        padding: rem-calc(0px 6px);
        display: flex;
        -webkit-box-pack: start;
        justify-content: flex-start;
        -webkit-box-align: baseline;
        align-items: baseline;
        margin: rem-calc(16px 0px);
        /* legacy styles override */
        flex: initial;
        max-width: initial;
        /* to do section contect inline */
        flex-direction: column;

        @include sm {
          width: calc(66.6667%);
          padding: 0px calc(8px);
        }

        @include md {
          width: calc(75%);
          padding: rem-calc(0px 8px);
          margin: rem-calc(24px 0px 0px);
        }

        .list-type-disc {
          color: inherit;
          font-size: inherit;
          padding: 0;
        }

        p {
          font-family: var(--lib-font-family-sans);
          font-weight: normal;
          line-height: 150%;
          font-size: $size-n;
          letter-spacing: $size-none;
          margin: rem-calc(0px 0px 15px);
          padding-left: rem-calc(35px);

          span {
            display: inline-block;
            background-position: left;
          }
        }

        .infoBanner {
          padding-left: 0;
        }
      }

      > section:last-child,
      > div:last-child {
        -webkit-box-flex: 0;
        flex-grow: 0;
        flex-basis: auto;
        box-sizing: border-box;
        width: calc(25%);
        padding: rem-calc(0px 6px);
        display: flex;
        -webkit-box-pack: end;
        justify-content: flex-end;
        -webkit-box-align: start;
        align-items: start;
        position: relative;
        margin: rem-calc(16px 0px);
        /* legacy styles override */
        flex: initial;
        max-width: initial;

        @media screen and (min-width: 415px) {
          width: calc(33.3333%);
          padding: 0px calc(8px);
        }

        @media screen and (min-width: 768px) {
          width: calc(25%);
          padding: rem-calc(0px 8px);
          margin: rem-calc(24px 0px);
        }

        .close-banner {
          width: rem-calc(36px);
          height: rem-calc(36px);
          text-decoration: none;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          vertical-align: middle;
          cursor: pointer;
          border: none;
          border-radius: 9999px;
          color: var(--lib-color-brand-base);

          &:after {
            @extend .fas;
            content: fa-content($fa-var-xmark);
          }

          &:hover {
            color: var(--lib-color-neutral-white);
            background-color: var(--lib-color-brand-dark);
          }

          &:active {
            color: var(--lib-color-neutral-white);
            background-color: var(--lib-color-brand-darker);
          }

          &:disabled {
            color: var(--lib-color-neutral-300);
          }
        }
      }
    }
  }
}
