#location-select .checkout-modal {
  height: 0;
}
.locationDetails {
  padding: 15px;
  color: var(--lib-color-neutral-white);
  font-weight: 100;
  font-size: 0.75rem;
  border-bottom: 1px solid white;
  @media screen and (min-width: 1280px) {
    padding: 15px 30px 0 0;
    border-bottom: none;
  }
}

.locationDetails:hover {
  text-decoration: underline;
  cursor: pointer;
}

.modal-selector {
  .c-text-input {
    position: relative;
  }
  input {
    padding-left: 48px;
  }
  cx-icon {
    position: absolute;
    left: 16px;
    z-index: 1;
    top: 16px;
  }
  table {
    td {
      padding: 0 1.5rem;
    }
  }
  .custom-control-label::before,
  .custom-control-label::after {
    top: 5px;
    border: 1px solid var(--lib-color-brand-base);
    border-radius: 20px;
  }
  .modal-footer {
    border-top: none;
  }
  .radio_grp {
    border-bottom: 1px solid var(--lib-color-neutral-200);
    padding-bottom: 0.8rem;
  }
}
