cx-facet {
  &:nth-of-type(n + 1):not(.expanded) a {
    display: flex;
  }
  button.heading {
    color: var(--lib-color-neutral-900);
    font-family: var(--lib-font-family-sans);
    font-weight: bold;
    font-size: 1.25rem;
    margin: 0 0 0.5rem;
    user-select: none;
    line-height: 150% !important;
    padding: 0;
    .collapse-icon.cx-icon.fas.fa-minus,
    .cx-icon {
      display: none;
    }
    border: none;
    &:hover {
      color: var(--lib-color-neutral-900);
    }
  }
  a {
    font-size: 1rem;
    color: var(--lib-color-neutral-900);
    font-family: var(--lib-font-family-sans);
    padding: 0.5rem 0 0.5rem;
    &:focus {
      text-decoration: none;
      outline: none;
    }
  }
  &.multi-select a.value {
    &:hover {
      color: var(--lib-color-brand-base);
      &:not(.selected)::before {
        border-color: var(--lib-color-brand-dark);
      }
    }

    &::before {
      width: 18px;
      height: 18px;
      border: solid 2px var(--lib-color-brand-base);
    }
    &.selected::before {
      background-color: var(--lib-color-brand-base);
      border: solid 2px var(--lib-color-brand-base);
      color: var(--lib-color-brand-base);
    }
  }
}
