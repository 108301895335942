:root {
  --lib-font-family-sans: "Sanofi Sans", "Work Sans", "Raleway", "Roboto",
    sans-serif;
  --lib-font-family-serif: "Sanofi Serif", "IBM Plex Serif", serif;
  --lib-font-family-display: var(--lib-font-family-sans);
  --lib-font-basesize: 16px;
}

/* Sanofi Sans Regular */
@font-face {
  font-family: "Sanofi Sans";
  src: url("../fonts/sanofi-sans/woff2/Sanofi_Sans-Regular.woff2")
      format("woff2"),
    url("../fonts/sanofi-sans/ttf/SanofiSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
/* Sanofi Sans Regular Italic */
@font-face {
  font-family: "Sanofi Sans";
  src: url("../fonts/sanofi-sans/woff2/Sanofi_Sans-Italic.woff2")
      format("woff2"),
    url("../fonts/sanofi-sans/ttf/SanofiSans-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
/* Sanofi Sans Bold */
@font-face {
  font-family: "Sanofi Sans";
  src: url("../fonts/sanofi-sans/woff2/Sanofi_Sans-Bold.woff2") format("woff2"),
    url("../fonts/sanofi-sans/ttf/SanofiSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
/* Sanofi Sans Bold Italic */
@font-face {
  font-family: "Sanofi Sans";
  src: url("../fonts/sanofi-sans/woff2/Sanofi_Sans-Bold_Italic.woff2")
      format("woff2"),
    url("../fonts/sanofi-sans/ttf/SanofiSans-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
/* Sanofi Sans Light */
@font-face {
  font-family: "Sanofi Sans";
  src: url("../fonts/sanofi-sans/woff2/Sanofi_Sans-Light.woff2") format("woff2"),
    url("../fonts/sanofi-sans/ttf/SanofiSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
/* Sanofi Sans Light Italic */
@font-face {
  font-family: "Sanofi Sans";
  src: url("../fonts/sanofi-sans/woff2/Sanofi_Sans-Light_Italic.woff2")
      format("woff2"),
    url("../fonts/sanofi-sans/ttf/SanofiSans-LightItalic.ttf")
      format("truetype");
  font-weight: 300;
  font-style: italic;
}
/* Sanofi Serif Regular */
@font-face {
  font-family: "Sanofi Serif";
  src: url("../fonts/sanofi-serif/woff2/Sanofi_Serif-Regular.woff2")
      format("woff2"),
    url("../fonts/sanofi-serif/ttf/SanofiSerif-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
/* Sanofi Serif Regular Italic */
@font-face {
  font-family: "Sanofi Serif";
  src: url("../fonts/sanofi-serif/woff2/Sanofi_Serif-Italic.woff2")
      format("woff2"),
    url("../fonts/sanofi-serif/ttf/SanofiSerif-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
/* Sanofi Serif  Bold */
@font-face {
  font-family: "Sanofi Serif";
  src: url("../fonts/sanofi-serif/woff2/Sanofi_Serif-Bold.woff2")
      format("woff2"),
    url("../fonts/sanofi-serif/ttf/SanofiSerif-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
/* Sanofi Serif Bold Italic */
@font-face {
  font-family: "Sanofi Serif";
  src: url("../fonts/sanofi-serif/woff2/Sanofi_Serif-Bold_Italic.woff2")
      format("woff2"),
    url("../fonts/sanofi-serif/ttf/SanofiSerif-BoldItalic.ttf")
      format("truetype");
  font-weight: 700;
  font-style: italic;
}
/* Sanofi Serif Light */
@font-face {
  font-family: "Sanofi Serif";
  src: url("../fonts/sanofi-serif/woff2/Sanofi_Serif-Light.woff2")
      format("woff2"),
    url("../fonts/sanofi-serif/ttf/SanofiSerif-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
/* Sanofi Serif Light Italic */
@font-face {
  font-family: "Sanofi Serif";
  src: url("../fonts/sanofi-serif/woff2/Sanofi_Serif-Light_Italic.woff2")
      format("woff2"),
    url("../fonts/sanofi-serif/ttf/SanofiSerif-LightItalic.ttf")
      format("truetype");
  font-weight: 300;
  font-style: italic;
}
