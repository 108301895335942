@use "../01-settings/settings.breakpoints" as *;

.back-to-top-btn {
  background-color: transparent;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 28px;
  padding-bottom: 44px;
  i {
    font-size: 20px;
    margin-left: 10px;
  }
  @include md {
    position: absolute;
    top: 64px;
    right: 32px;
    margin-left: 0;
    padding-bottom: 0;
  }
  @include xl {
    right: 10%;
  }
}

.back-to-top-btn:hover span {
  text-decoration: underline;
}
