@use "../02-tools/tools.functions" as *;

.order-info {
  p {
    font-weight: bold;
    display: block;
    text-align: left;
  }
  .order-details {
    padding-top: 0;
    &hover {
      text-decoration: none;
    }
    a {
      display: flex;
      align-items: center;
      margin-top: rem-calc(25px);
      .email-logo {
        margin: rem-calc(4px 15px 0 0);
      }
    }
  }
}
.order-info span {
  padding-right: 0px;
  text-align: left;
  display: inline-block;
  font-weight: normal;
  margin-top: 10px;
}
.details-custom-text{
  padding-top: 20px;
}

.email-id {
  color: var(--lib-color-brand-base);
  word-wrap: break-word;
  font-size: rem-calc(16px);
}
.email-id:hover {
  text-decoration: underline;
  color: var(--lib-color-brand-base);
}
.order-info .order-details a:hover {
  text-decoration: underline;
  color: var(--lib-color-brand-base);
}
.order-info .order-details span:not(.contact-logo, .email-logo, .email-id) {
  position: relative;
  padding-left: rem-calc(30px);
  &::after {
    content: "";
    background-image: url(../icons/phone-call.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: rem-calc(20px);

    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.email-logo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
  // padding: 21px 21px 2px 28px;
  position: relative;
  display: inline-block;
  // padding: rem-calc(10px 15px 0);
  font-size: rem-calc(20px);
  &::after {
    content: "\f0e0";
    font-family: var(--fa-style-family, "Font Awesome 6 Free");
  }
}

.order-history-timings {
  margin-bottom: rem-calc(25px);
}
