@use "../02-tools/tools.functions" as *;
@use "../01-settings/settings.variables" as *;

.c-list-orders {
    &--aside {
        padding: rem-calc(24px 14px);
        box-shadow: var(--lib-box-shadow-spread-distance);

        fieldset {
            display: flex;
            flex-direction: column;
            gap: rem-calc(32px);
        }
    }
}
