cx-page-layout.footer .Footer {
  cx-banner:nth-child(1) {
    padding-top: 32px;
    padding-left: 18px;

    @include media-breakpoint-up(xl) {
      padding-left: 0;
      transform: translateX(-14px);
    }
  }
  cx-footer-navigation {
    padding: 0;
    margin: 0;
    max-width: none;
    cx-navigation-ui {
      margin: 0;
      padding: 0;
      margin-top: 48px;

      nav {
        width: 100%;
        & > ul {
          width: 100%;
          display: flex;
          flex-flow: column;

          & > li {
            padding: 0 32px;
            margin: 0;

            @include media-breakpoint-up(xl) {
              padding: 0;
              max-width: 1280px;
              width: 100%;
              margin: auto;
            }

            &:first-child {
              padding-bottom: 100px;
              @include media-breakpoint-up(md) {
                padding-bottom: 20px;
              }
            }

            &:not(:first-child) {
              background-color: white;
              margin: 0;
              color: black;
              padding-top: 20px;

              @include media-breakpoint-up(xl) {
                max-width: none;
                width: 100%;
              }

              .wrapper {
                ul {
                  display: flex;
                  flex-flow: column;
                  gap: 20px;

                  @include media-breakpoint-up(md) {
                    flex-flow: row;
                    gap: 10px;
                  }

                  @include media-breakpoint-up(xl) {
                    max-width: 1280px;
                    margin: auto;
                  }

                  li {
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  cx-paragraph {
    max-width: none;
    div {
      padding: 0;
      @include media-breakpoint-up(xl) {
        max-width: 1280px;
        margin: auto;
      }
    }
    padding: 45px 32px 30px;
    background-color: white;

    @include media-breakpoint-up(md) {
      padding: 20px 32px 30px;
    }
  }

  app-back-to-top {
    @include media-breakpoint-down(sm) {
      position: absolute;
      top: 48%;

      button {
        margin: 0;
        padding: 0 32px;
      }
    }
  }
}
