.c-force-wrap {
    overflow-wrap: break-word;
}

@media print {
  @page {
    size: 330mm 427mm;
    margin: 14mm;
  }
  .container {
    width: 1170px;
  }
}