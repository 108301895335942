@use "../01-settings/settings.breakpoints" as *;
@use "../01-settings/settings.variables" as *;
@use "../02-tools/tools.functions" as *;

cx-breadcrumb {
  background-color: transparent;
  padding: 0 rem-calc(15px);
  box-shadow: none;
}

cx-breadcrumb h1 {
  font-family: var(--lib-font-family-sans);
  font-weight: bold;
  line-height: 125%;
  letter-spacing: 0px;
  font-size: 2rem;
  color: var(--lib-color-neutral-black);
  text-align: left;
  @include md {
    font-size: 2.2rem;
  }
  @include xl {
    font-size: 3rem;
  }
}

cx-breadcrumb nav {
  padding: 20px 0px 20px 0px;
  justify-content: flex-start;
  ol li {
    text-transform: capitalize;
    color: inherit;
    padding: rem-calc(5px) 0 rem-calc(5px) 0;
    font-size: rem-calc(14px);
    a {
      color: var(--lib-color-brand-base);
      text-decoration: underline;
      &:hover,
      &:focus {
        color: var(--lib-color-brand-dark);
        outline: none;
      }
    }
  }
}

@include lg {
  cx-breadcrumb {
    // width: 66% !important;
    display: inline;
    float: left;
    padding: 0px 0px 0px 10px;
  }

  // cx-breadcrumb nav {
  //   font-size: 0.85rem;
  //   padding: 20px 12px 20px 0px;
  //   display: flex;
  //   justify-content: flex-start;
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;

  //   font-size: 11px;
  //   font-family: "NotoSans", sans-serif !important;
  // }

  // .BottomHeaderSlot cx-breadcrumb nav span {
  //   color: #337ab7;
  // }

  // cx-breadcrumb nav span a:hover {
  //   color: #337ab7;
  //   cursor: pointer;
  //   text-decoration: underline;
  // }

  cx-breadcrumb nav span a header .SiteLogin {
    display: none;
  }
}
