/*
.u-invisible {
  visibility: hidden;
}

.u-hidden {
  display: none;
}

@media screen and (min-width: $screen-small) {
  .u-hidden--s-up {
    display: none;
  }
}
@media screen and (min-width: $screen-medium) {
  .u-hidden--m-up {
    display: none;
  }
}
@media screen and (min-width: $screen-large) {
  .u-hidden--l-up {
    display: none;
  }
}
*/

.u-hide-if-empty:empty {
  display: none;
}

.u-hide-if-empty-child {
  &:has(div:empty) {
    display: none;
  }
  &:has(section:empty) {
    display: none;
  }
}

.u-overlow-wrap-break {
  overflow-wrap: break-word;
}