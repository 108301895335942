@use '../../../../library/01-settings/settings.breakpoints';
.monographCompSlot {
  background-color: #fff;
  border-right: 1px solid var(--lib-color-neutral-200);
  min-height: 930px;
  padding-top: 10px;
  margin-bottom: 40px;
  .product-monograph {
    padding-left: 15px;
    padding-right: 15px;
    @include md() {
      min-height: 570px;
    }
  }
  @include md() {
    width: 70%;
    float: left;
    padding-right: 30px;
  }
  .c-link {
    white-space: normal !important;
  }
}
