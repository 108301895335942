@media (min-width: 992px) {
  cx-page-layout.ProductDetailsPageTemplate cx-page-slot.Summary {
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 0px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto) 1fr;
    grid-template-rows: repeat(5, auto) 1fr;
    padding: 0 px;
  }
}

cx-page-layout.ProductDetailsPageTemplate {
  padding: 0px;
}

cx-product-references {
  display: none;
}
@media (max-width: 991.98px) {
  .modal-body cx-media img {
    max-width: 100% !important;
  }
}

// @media (max-width: 991.98px){
//     app-custom-pdp cx-media img {
//         max-width: -webkit-fit-content;
//         max-width: -moz-fit-content;
//         max-width: 222px;
//     }
// }
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  app-custom-pdp cx-media img {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: 222px !important;
  }
}
@media only screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
  app-custom-pdp cx-media img {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: 315px;
  }
}
