@use "../01-settings/settings.variables" as *;
@use "../01-settings/settings.breakpoints" as *;

cx-page-layout.footer {
  background-color: var(--lib-color-neutral-black);
  .Footer {
    flex-flow: column;
    position: relative;
  }
  a {
    font-size: $size-n;
    min-height: auto;
    &:hover {
      text-decoration: underline;
    }
    &:active {
      color: var(--lib-color-brand-lighter);
    }
  }
  .Footer ul.childs li {
    margin-bottom: 16px;
  }
  // Logo
  cx-page-slot cx-banner:nth-child(1) {
    max-width: var(--lib-container-width);
    padding-top: 50px;
    margin: 0 auto;
    width: 100%;
    padding-left: 32px;
    padding-right: 32px;
    img {
      max-width: 80px;
    }

    @include xl {
      padding-left: 0;
      padding-right: 0;
      margin: 0 auto;
    }
  }
  // Navigations
  .Footer cx-footer-navigation {
    background-color: transparent;
    max-width: var(--lib-container-width);
    padding-bottom: 40px;
    margin: initial;
    width: 100%;
    @include md {
      margin: 0 auto;
      padding-left: 32px;
      padding-right: 32px;
    }
    @include xl {
      padding-left: 0;
      padding-right: 0;
    }
    cx-navigation-ui {
      background-color: transparent;
      justify-content: flex-start;
      margin: 48px 0 0;
      padding: 0 32px 0;
      ul {
        li {
          margin: 0;
        }
      }
      span {
        text-transform: capitalize;
        font-family: NotoSansBold, sans-serif;
        font-size: $size-l;
        margin-bottom: 1.25rem;
        padding: 0;
      }
      @include md {
        padding: 0;
        ul li {
          margin: 0 64px 0 0;
        }
      }
    }
  }
  cx-page-slot cx-banner:nth-child(3) {
    display: none;
  }
  .BottomFooter {
    background-color: var(--lib-color-neutral-50);
    padding: 20px 32px 40px;
    max-width: var(--lib-container-width);
    margin: 0 auto;
    position: relative;
    &::before {
      position: absolute;
      width: 100vw;
      height: 100%;
      background-color: var(--lib-color-neutral-50);
      content: "";
      top: 0;
    }

    cx-footer-navigation {
      background-color: var(--lib-color-neutral-50);
      flex: inherit;
      cx-navigation-ui {
        background-color: var(--lib-color-neutral-50);
        color: var(--lib-color-neutral-900);
        font-size: $size-n;
        max-width: var(--lib-container-width);
        margin: 0 auto 25px;
        justify-content: flex-start;
        nav ul {
          display: block;
          li {
            padding: 0;
            margin: 0;
            a {
              margin: 0 10px 20px 0;
            }
            list-style: none;
            padding: 0;
          }
          padding: 0;
          margin: 0;
        }

        a:hover {
          color: var(--lib-color-neutral-900);
          text-decoration: underline;
        }
        .all-rights {
          margin-top: 30px;
          max-width: var(--lib-container-width);
          background-color: var(--lib-color-neutral-50);
          color: var(--lib-color-neutral-500);
          font-size: $size-s;
        }
        @include md {
          nav ul {
            display: flex;
            li a {
              margin: 0 20px 0 0;
            }
          }
        }
      }
    }
    app-custom-bottom-footer-links {
      align-items: center;
      display: flex;
      height: auto;
      margin-right: 15px;
      flex: auto;
      button {
        border: none;
        text-decoration: underline;
        background-color: transparent;
        padding: 0;
        text-align: left;
        color: var(--lib-color-neutral-900);
      }
    }
    cx-footer-navigation,
    app-custom-bottom-footer-links {
      position: relative;
      z-index: 1;
    }
    @include lg {
      padding: 20px 30px 30px;
      app-custom-bottom-footer-links {
        flex: initial;
        height: 59px;
      }
      cx-footer-navigation {
        flex: 3;
      }
    }
    @include xl {
      padding: 20px 0 30px;
    }
  }
  cx-paragraph {
    max-width: var(--lib-container-width);
    background-color: var(--lib-color-neutral-50);
    color: var(--lib-color-neutral-500);
    font-size: $size-s;
    position: relative;
    z-index: 1;
  }
}
