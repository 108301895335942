@use "../01-settings/settings.breakpoints" as *;
@use "../01-settings/settings.variables" as *;
@use "../02-tools/tools.functions" as *;
// Old
header {
  background: none;
  background-color: var(--lib-color-neutral-white);
}
header .header {
  justify-content: flex-start;
  @include lg {
    justify-content: space-around;
  }
}

header .SiteContext > cx-paragraph > div {
  //   background-image: url("../../../assets/icons/healthcare-logo.png");
  background-position: 0;
  background-repeat: no-repeat;
  background-size: 15px;
  padding: 0 0 0 22px;
}

.SiteLinks {
  & cx-navigation-ui.flyout > nav > ul > li {
    position: relative;
    & > button {
      padding: 10px 15px 13px;
      margin-right: 34px;
      font-size: $size-xs;
      color: var(--lib-color-neutral-white);
      text-transform: none;
      font-weight: 100;
      margin-top: 4px;
    }

    & > .wrapper {
      padding: 15px 20px;
      box-shadow: 0 2px 3px 0 rgba(23, 114, 183, 0.2);
      border: none;

      & > nav {
        padding-right: 1.5625rem;
      }
    }
  }

  cx-navigation-ui nav ul.childs > li > cx-generic-link {
    border: none;
    > a {
      font-weight: 400;
      font-size: $size-s-px;
      text-decoration: none;
      color: var(--lib-color-neutral-white);
      border-bottom: none;
      padding: 0.5625rem 0 0.625rem;
      @include lg {
        color: var(--lib-color-brand-base);
        font-size: $size-s;
      }
      &:hover {
        text-decoration: underline;
      }
      &:active {
        color: var(--lib-color-brand-lighter);
      }
    }
  }
  @media (max-width: 767.98px) {
    & cx-navigation {
      width: 100%;
      background-color: #2a343f;
    }
  }
}

.navigation {
  cx-navigation-ui.flyout nav ul li {
    position: relative;
    > cx-generic-link + button {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      flex-flow: row-reverse;
      color: var(--lib-color-brand-base);
    }
    > cx-generic-link {
      color: var(--lib-color-brand-base);
      text-transform: capitalize;
      font-weight: 400;
      font-size: $size-n;
      letter-spacing: 0.5px;
    }
  }
}

@media (max-width: 768px) {
  cx-navigation-ui.flyout.is-open li.is-open {
    background-color: var(--lib-color-neutral-white);

    &.back {
      border-bottom: 1px solid #d3d6db;
      cx-icon {
        color: var(--lib-color-brand-base);
      }
      button {
        font-size: $size-s;
        font-weight: normal;
        color: var(--lib-color-brand-base);
      }
    }

    & > cx-generic-link {
      display: none;
    }
  }
}

.header {
  cx-site-context-selector {
    label > span {
      display: none;
    }

    select,
    cx-icon {
      color: white;
    }
  }
}

.header .NavigationBar {
  cx-navigation-ui.flyout {
    > nav > ul > li {
      position: relative;
      margin-right: 30px;
      margin-top: 3px;
      & > button {
        position: absolute;
        width: 100%;
        display: block;
        text-align: right;
        padding-top: 22px;
        padding-bottom: 12px;
      }
    }
    cx-generic-link {
      margin-right: 5px;
      color: var(--lib-color-brand-base);
      text-transform: capitalize;
    }
  }

  cx-navigation-ui {
    background-color: transparent;
    padding: 10px 0px 0 50px;
    min-height: 67px;
    position: relative;
    cx-generic-link {
      a {
        text-transform: capitalize;
        font-size: rem-calc(36px);
        cursor: pointer;
        font-family: var(--lib-font-family-sans);
        font-weight: 700;
        letter-spacing: 0px;
        padding-top: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
    button .fa-angle-down::before {
      color: var(--lib-color-brand-base);
    }
    & > nav > ul > li > .wrapper {
      border: none;
      box-shadow: 0 2px 3px 0 var(--lib-color-brand-lighter);
      padding: 3px 16px;
      margin: -12px -20px;
      margin-top: 45px;

      & a {
        border-bottom: 1px solid (--lib-color-neutral-900);
        padding: 10px 0;
        font-weight: normal;
        font-size: $size-s;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.cart-icons {
  & a {
    display: inline;
  }
}

//Header
header {
  background-color: var(--lib-color-neutral-white);
  min-height: 50px;
  @include lg {
    min-height: 130px;
    // position: fixed;
    margin: 0 auto;
    width: 100%;
  }
  .header {
    max-width: 1360px;
    margin: 0 auto;
  }
  .SiteLinks {
    background-color: var(--lib-color-neutral-black);
    width: 100%;
    --cx-margin: 0;
    padding: 0;
    //Mobile top header navigation open
    cx-navigation-ui.flyout.accNavComponent nav li.is-open {
      background-color: var(--lib-color-neutral-black);
      .wrapper {
        padding: 15px;
        @include lg {
          padding: 15px;
        }
      }
    }
    cx-link {
      padding: 15px;
      color: var(--lib-color-neutral-white);
      font-weight: 100;
      font-size: $size-xs;
      @include lg {
        padding: 15px 30px 0 0;
      }
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
        &:active {
          color: var(--lib-color-brand-lighter);
        }
      }
    }
    @include lg {
      min-width: 100%;
    }
    @include xl {
      min-width: none;
    }
  }
  .SiteLogo {
    transform: none;
    display: flex;
    align-items: center;
    max-width: 230px;
    width: auto;

    @include md {
      max-width: 270px;
      cx-media img {
        max-width: 270px;
      }
    }
    @include lg {
      align-items: normal;
      margin: 0;
      max-width: 100%;
      padding: 25px 0 0;
      width: 50.5%;
      cx-media {
        margin-top: 0;
        img {
          max-width: 430px;
        }
      }
    }
    @include xl {
      margin: 0 0 0 25px;
      width: 56.5%;
    }
  }
  //   Mobile
  .hamburger,
  .cx-hamburger {
    padding: 10px 15px 0;
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: var(--lib-color-brand-base);
  }
  .cx-hamburger.is-active .hamburger-inner,
  .cx-hamburger.is-active .hamburger-inner::before,
  .cx-hamburger.is-active .hamburger-inner::after {
    background-color: var(--lib-color-brand-base);
  }
  .cx-hamburger.is-active .hamburger-box {
    height: 28px;
  }
  &.is-expanded {
    .navigation {
      background-color: var(--lib-color-neutral-white);
      @include lg {
        display: none;
      }
    }
  }
  .NavigationBar {
    background-color: var(--lib-color-neutral-white);
    @include sm {
      background-color: transparent;
    }
  }
}

.HomePageTemplate .SiteLogo {
  width: 95%;
}
