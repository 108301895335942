//forgot password module
.LoginHelpPageTemplate {
  display: block;
}
.LoginHelpPageTemplate .BodyContent,
.LoginHelpPageTemplate .NeedAssistanceSlot {
  background: var(--lib-color-neutral-white);
  width: 100%;
  flex: none;
}
.LoginHelpPageTemplate .NeedAssistanceSlot {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 40px;
}
@media screen and (min-width: 768px) {
  .LoginHelpPageTemplate {
    display: flex;
  }
  .LoginHelpPageTemplate .BodyContent {
    width: 70%;
    border-right: 1px solid rgb(201, 201, 201);
    padding-right: 15px;
  }
  .LoginHelpPageTemplate .NeedAssistanceSlot {
    width: 30%;
    padding-left: 15px;
  }
}
@media screen and (min-width: 992px) {
  .LoginHelpPageTemplate .BodyContent {
    width: 75%;
  }
  .LoginHelpPageTemplate .NeedAssistanceSlot {
    width: 25%;
  }
}
