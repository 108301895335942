@use '../../../../library/01-settings/settings.breakpoints';

.LoginPageTemplate {
  cx-page-slot.AccountOverviewFinancialsSlot.has-components {
    max-width: 1380px;
    padding-top: 0;
  }
}

app-account-overview {
  min-width: 100% !important;
  margin: 0 !important;
}

@include lg {
  .SanofiAccountOverviewBannerSlot
    .account-overview-banner
    .acc-warning-box
    > div.row
    > div:first-child {
    width: 75%;
  }
}

.rem-leftSect {
  .LeftContentSlot {
    display: none;
  }
}