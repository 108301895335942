@use "../01-settings/settings.breakpoints" as *;
@use "../01-settings/settings.variables" as *;
@use "../02-tools/tools.functions" as *;

.cartvalue {
  position: absolute;
  background: var(--lib-color-brand-base);
  width: 25px;
  display: inline-block;
  height: 15px;
  z-index: 18;
  border-radius: 45px;
  color: var(--lib-color-neutral-white);
  text-align: center;
  line-height: 11px;
  font-size: 11px;
  padding: 2px 4px 1px 3px;
  margin: -2px 0px 0px -14px;
}
.novalue-cart {
  display: none;
}
.cart-prod-details {
  list-style: none;
}
.make-visible {
  visibility: visible;
}
.not-visible {
  visibility: hidden;
}

.cart-icon {
  cursor: pointer;
  width: 25px;
  @include md {
    margin: 0px 8px 0px 0px;
  }
}
.save-order {
  width: 100%;
  height: 28px;
  margin-bottom: 24px;
  font-family: "NotoSans", sans-serif !important;
  font-size: 12px !important;
  color: #2a343f !important;
  border-radius: 4px;
  box-shadow: inset 0 1px 5px 0 #d4d9de;
  border: solid 1px #d4d9de;
  background-color: #ffffff;
  display: inline-block;
  padding: 8px 12px;
}
.save-order:focus {
  border: solid 2px #2a343f;
  outline-style: none;
}

#miniCart {
  position: relative;
}

ngb-popover-window.minicart-std-popover {
  width: rem-calc(436px);
  max-width: rem-calc(436px);
  max-height: 534px;
  display: none !important;
  @include lg {
    display: block !important;
  }
  .popover-header {
    background-color: var(--lib-color-neutral-white);
    border-bottom: none;
    padding: 0;
    .minicart-header {
      background-color: var(--lib-color-neutral-white);
      font-size: rem-calc(18px);
      line-height: 1.25;
      color: var(--lib-color-neutral-black);
      padding: rem-calc(15px 0);
      margin: rem-calc(0 24px);
      border-bottom: 1px solid var(--lib-color-neutral-black);
    }
  }
  .popover-body {
    padding: rem-calc(0 0 20px);
    margin: rem-calc(0 24px);
    .unit-numbers {
      font-size: rem-calc(18px);
      line-height: 1.25;
      color: var(--lib-color-neutral-900);
      text-align: right;
      padding: rem-calc(15px 0);
      border-bottom: 1px solid var(--lib-color-neutral-black);
    }
    .cart-prod-list {
      padding: 0;
    }
    ul {
      padding: rem-calc(10px 0 0);
      margin: 0;
    }
    li {
      padding: rem-calc(10px 0);
      .miniCart-prod-details {
        display: flex;
        justify-content: space-between;
      }
      .img-info {
        display: flex;
        align-items: center;
        .info {
          font-size: rem-calc(16px);

          line-height: 1.5;
          .cart-prod-title {
            color: var(--lib-color-brand-base);
            border-bottom: 1px solid var(--lib-color-brand-base);
          }
          .miniCart-price {
            margin: 0;
          }
        }
      }
      img {
        max-width: rem-calc(51px);
        margin-right: rem-calc(10px);
      }
    }
    .cart-footer.savepopover {
      display: flex;
      justify-content: space-between;
      margin-top: rem-calc(20px);
      button {
        width: 45%;
      }
    }
    // .savedordermini-popover {
    //   padding: rem-calc(0 15px 20px);
    //   margin: 0;
    //   .popover-body {
    //     margin: 0;
    //   }
    //   .popover-header {
    //     font-size: rem-calc(16px);
    //     font-family: var(--lib-font-family-sans);
    //     font-weight: 400;
    //     color: var(--lib-color-neutral-900);
    //   }
    //   input {
    //     border-radius: 4px;
    //     color: var(--lib-color-neutral-900);
    //     background-color: var(--lib-color-neutral-50);
    //     display: flex;
    //     width: 100%;
    //     position: relative;
    //     margin: 0.25rem 0;
    //     padding: 0.75rem 1rem;
    //     border: none;
    //     height: auto;
    //   }
    //   .common-error {
    //     font-size: rem-calc(14px);
    //     color: var(--lib-color-error-red);
    //   }
    // }
  }
}
