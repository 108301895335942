@use "../01-settings/settings.colours" as *;
@use "../01-settings/settings.fonts" as *;
@use "../01-settings/settings.variables" as *;
@use "../02-tools/tools.functions" as *;

a,
.a {
  color: var(--lib-color-link);
  &:hover,
  &:focus {
    color: var(--lib-color-link-hover);
    text-decoration: underline;
  }
}

// Use this class, when you want a <button> to be displayed like a link
.a {
  display: inline;
  padding: 0;
  border: 0;
  background: transparent;
  text-decoration: underline;
  cursor: pointer;
  appearance: none;

  // This is used to link containers
  &--no-styles {
    text-decoration: none;

    &:active {
    }
  }

  &--no-strict-color {
    color: inherit;
  }
}

.c-link,
.c-table a {
  display: inline-flex;
  position: relative;
  -webkit-box-align: center;
  align-items: center;
  color: var(--lib-color-brand-base);
  border-radius: $radius-n;
  width: fit-content;
  cursor: pointer;
  border: none;
  text-underline-offset: 3px;
  box-sizing: border-box;
  font-family: var(--lib-font-family-sans);
  font-weight: normal;
  line-height: 125%;
  font-size: $size-n;
  letter-spacing: $size-none;
  text-decoration: underline;
  flex-wrap: nowrap;
  white-space: nowrap;

  &:hover,
  &:active {
    color: var(--lib-color-brand-dark);
  }

  &:focus {
    color: var(--lib-color-brand-base);
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    color: var(--lib-color-neutral-300);
  }

  & .icon {
    margin-left: 4px;
    width: $size-n;
    height: $size-n;
  }

  & i:first-child {
    margin-right: 4px;
    width: $size-n;
    height: $size-n;

    &::before {
      display: inline-block;
    }
  }

  &--inverse {
    color: var(--lib-color-neutral-white);

    &:hover {
      color: var(--lib-color-brand-lightest);
    }

    &:active {
      color: var(--lib-color-brand-lighter);
    }

    &:focus {
      color: var(--lib-color-neutral-white);
      outline: none;
    }

    &:disabled {
      cursor: not-allowed;
      color: var(--lib-color-neutral-300);
    }
  }
  &-wrap {
    white-space: pre-wrap;
  }
}

.c-linked-heading {
  color: var(--lib-color-brand-base);
  text-decoration: none;

  &:hover,
  &:active {
    color: var(--lib-color-brand-dark);
    text-decoration: none;
  }

  &:focus {
    color: var(--lib-color-brand-base);
    outline: none;
    text-decoration: none;
  }

  &:disabled {
    cursor: not-allowed;
    color: var(--lib-color-neutral-300);
  }
}

.preheader a.c-link {
  font-size: $size-s;
}
