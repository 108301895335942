.sanofiResourcesPageTemplate {
  display: flex;
  width: 100%;
}
.MedicalResourceComp .medicalResourceSection .product-details {
  list-style: none;
  padding: 0;
  margin: 0;
}
.MedicalResourceComp .medicalResourceSection .product-details .content-desc {
  margin-bottom: 0;
  font-family: var(--lib-font-family-sans);
  font-weight: bold;
}
.medicalResourceSection .product-details li h3 {
  font-weight: 100;
  line-height: 1.2222222222;
  margin-top: 20px;
  margin-bottom: 10px;
}

.MedicalResourceComp .medicalResourceSection .product-details .title a {
  background-image: url('../../assets/icons/external-link.svg') !important;
  background-position: right;
  background-repeat: no-repeat;
  background-size: 20px;
  padding: 0 25px 2px 28px !important;
  margin-left: -28px;

  color: var(--lib-color-brand-base);

  border: none;
  text-underline-offset: 3px;
  box-sizing: border-box;
  font-family: var(--lib-font-family-sans);
  font-weight: 400;
  line-height: 125%;
  font-size: 1rem;
  letter-spacing: 0;
  text-decoration: underline;
}
.MedicalResourceComp .medicalResourceSection .product-details .title a:hover {
  color: var(--lib-color-brand-dark);
}
.MedicalResourceComp .medicalResourceSection .product-details li {
  img {
    display: none;
  }
  p {
    font-family: var(--lib-font-family-sans);
    font-weight: 400;
    line-height: 150%;
    font-size: 1rem;
    letter-spacing: 0px;
  }
}

.MedicalResourceComp {
  float: left;
  width: 70%;
  background-color: white;
  border-right: 1px solid var(--lib-color-neutral-200);
  min-height: 930px;
  padding-top: 10px;
}

.healthcarevertical {
  background-color: white;
  display: grid;
  align-self: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.healthcarehorizantal {
  background-color: white;
  display: grid;
  align-self: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media only screen and (max-width: 767px) and (min-width: 320px) and (orientation: portrait) {
  .MedicalResourceComp {
    width: 100%;
    background-color: white;
    padding-left: 20px;
    padding-right: 15px;
    float: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1025px) {
  .healthcarevertical {
    background-color: white;
    display: grid;
    align-self: center;
    padding: 20px !important;
  }
}
