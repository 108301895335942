@use "../01-settings/settings.variables" as *;

.c-icon-primary {
  color: var(--lib-color-brand-base);
  &-sm {
    font-size: $size-n;
  }
  &-md {
    font-size: $size-xl;
  }
}
.c-icon-round-hover {
  border-radius: 25px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: var(--lib-color-neutral-white);
    background-color: var(--lib-color-brand-base);
    border-radius: 25px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
