// Checkout Modal
.modal-spinner {
  position: absolute;
  background-color: var(--lib-color-neutral-300);
  width: calc(100% - 20px);
  height: 115px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  &-animation {
    animation: load8 var(--cx-spinner-animation-time) infinite linear;
  }
  @media screen and (min-width: 769px) {
    height: 50px;
  }
}
