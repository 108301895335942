@use '../01-settings/settings.colours' as *;
@use '../01-settings/settings.fonts' as *;
@use '../01-settings/settings.variables' as *;
@use "../01-settings/settings.breakpoints" as *;
@use '../02-tools/tools.functions' as *;

.user-form form .c-login-form,
.c-login-form {
    padding: rem-calc(24px);
    background-color: var(--lib-color-neutral-white);
    color: var(--lib-color-neutral-900);
    border-radius: $radius-n;
    border: 2px var(--lib-color-brand-darkest) solid;

    @include sm {
        max-width: 384px;
    }

    &--content {
        display: flex;
        flex-direction: column;
        gap: rem-calc(24px);
    }

    /* to override user-form styles */
    label {
        all: inherit;
        margin-bottom: rem-calc(4px);
    }

    p {
        margin: 0;
    }

    /* to override user-form styles */
    button,
    a {
        margin-top: initial;
        white-space: initial;
    }

    &--forgot {
        margin-bottom: rem-calc(15px);
    }

    &--are-you-new-user {
        font-size: rem-calc(18px);
    }
}
