app-influenza-dashboard,
app-flu-reservation-details {
  width: 100%;
}

.alert-Influenza-Banner {
  min-height: 79px;
  display: block;
  background-color: var(--lib-color-neutral-white);
  box-shadow: var(--lib-box-shadow-spread-distance);
  position: inherit;
  width: auto;
  border-bottom: none;
  margin-top: .1875rem;
  margin-bottom: 1.25rem;

  .alert-close{
    margin-top: 10px;
  }

  .close-banner {
    width: 2.25rem;
    height: 2.25rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    cursor: pointer;
    border: none;
    border-radius: 9999px;
    color: var(--lib-color-brand-base);

    &:hover {
      color: var(--lib-color-neutral-white);
      background-color: var(--lib-color-brand-dark);
    }

    &:after {
      font-family: 'Font Awesome 6 Free';
      font-weight: 900;
      content: "\f00d";
    }
  }
}

.reservation-history-dropdown {
  .ng-option,
  .ng-option-marked,
  .ng-value-label {
    //color: #337ab7 !important;
    color: #5b646e;
    font-size: 0.9em !important;
  }

  &.ng-select .ng-arrow-wrapper,
  &.ng-select.ng-select-single .ng-arrow-wrapper {
    top: -3px;

    .ng-arrow {
      border-color: #5b646e;
    }
  }
}

.displayNone {
  display: none !important;
}
.warning-text {
  display: inline-block;
  font-family: 'Poppins-medium', sans-serif !important;
  font-size: 1.67em !important;
  font-weight: 500 !important;
  color: #2a343f !important;
  margin-left: 0px !important;
  background-image: url('../../assets/icons/warning.svg') !important;
  background-repeat: no-repeat !important;
}
.influenza-Msg-Banner {
  font-family: 'Poppins-medium', sans-serif;
  font-size: 1.67em;
  font-weight: 500;
  color: #2a343f;
  margin: 0 0 0 5px;
  position: relative;
  bottom: 6px;
}
.infoBanner-Influenza {
  margin: 2% 0 0 1%;
  padding: 0;
}
.noReserve-Middle-Section-Content {
  min-height: 280px;
  background: white;
  padding: 4% 0 0 14%;
}
.noReserve-Middle-Chart-Content {
  min-height: 280px;
  background: white;
}

.noReserve-Middle-Section-Header {
  color: $color-neutral-black;
}
.reserve-Msg {
  color: #5b646e;
  margin-top: 0;
  font-size: 20px;
  font-family: 'Poppins-Medium', sans-serif;
  line-height: 24px;
}
.influenza-Vaccine-Span {
  font-size: 1.9em;
}
.influenza-Vaccine-Active-Season-Span {
  font-size: 1.2em;
}
.btn-Reserve-Section {
  margin: 10% 0 0 0;
}
.btn-Reserve {
  background: #0057a6 !important;
  color: #ffffff !important;
  border-color: #0057a6 !important;
}

.btn-reserve-align {
  margin: 4px 26px 40px;
}
.new-res-padding .new-res {
  padding-bottom: 0;
}
.new-res-heading {
  color: #5b646e;
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 20px;
  font-family: 'Poppins-Medium', sans-serif;
  line-height: 24px;
}
.new-res ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.new-res span {
  color: #2a343f;
}
@media screen and (min-width: 300px) and (max-width: 700px) {
  .noReserve-Middle-Section-Content {
    min-height: 280px;
    background: white;
    padding: 4% 0 0 3%;
  }
  .btn-Reserve-Section {
    margin: 50% 0 0 0;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .btn-Reserve-Section {
    margin: 20% 0 0 0;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .btn-Reserve-Section {
    margin: 20% 0 0 0;
  }
}

//to cover iphone and android
@media screen and (min-device-width: 300px) and (max-device-width: 700px) and (orientation: portrait) {
  app-influenza-dashboard {
    min-width: 100%;
  }
  app-flu-confirmation {
    min-width: 100%;
  }
  app-flu-confirmation {
    min-width: 100%;
  }
}
@media (min-width: 576px) {
  .checkout-Container-rwd,
  .est-checkout-Container {
    max-width: 100% !important;
  }
  .mobiledb-Container,
  .active-season-container,
  .footer-Container-Section,
  .infcontact-Container {
    max-width: 100%;
  }
}

.influenza-vaccine-reservation {
  &__id {
    span {
      font-size: 18px;
      font-family: 'NotoSansBold', sans-serif;

      &:first-child {
        font-size: 28px;
      }
    }
  }

  &__season {
    font-size: 14px;
    font-weight: 300;
  }
}
