@use "../01-settings/settings.colours" as *;
@use "../01-settings/settings.fonts" as *;
@use "../01-settings/settings.variables" as *;
@use "../02-tools/tools.functions" as *;
.NeedAssistanceSlot {
  font-size: rem-calc(16px);
  color: var(--lib-color-neutral-800);
  a {
    font-size: rem-calc(14px);
    color: var(--lib-color-brand-base);
    text-decoration: underline;
  }
  .contact-img,
  .email-img {
    position: relative;
    display: inline-block;
    margin-right: rem-calc(15px);
    font-size: rem-calc(20px);
  }
  .contact-img {
    margin-bottom: rem-calc(25px);
    width: rem-calc(25px);
    height: rem-calc(25px);
    background-image: url("../icons/phone-call.svg");
  }
  .email-img {
    &::after {
      content: "\f0e0";
      font-family: var(--fa-style-family, "Font Awesome 6 Free");
    }
  }
}
.Need-assistance {
  line-height: 1.5;
  width: 100%;
  font-weight: bold;
}
.Contact-support {
  margin-bottom: rem-calc(25px);
  span {
    display: block;
    &:nth-child(1) {
      margin-bottom: rem-calc(25px);
    }
  }
}
.Contact-details {
  display: flex;
}
