@use "../01-settings/settings.variables" as *;
.c-popover {
  width: auto;
  padding: 8px 10px;
  border-radius: 5px;
  text-align: left;
  line-height: 130%;
  p {
    margin: 0;
    font-size: $size-s;
    span {
      font-size: $size-xs;
    }
  }

  &-black {
    background-color: var(--lib-color-neutral-black);
    color: var(--lib-color-neutral-white);
  }

  &-white {
    background-color: var(--lib-color-neutral-white);
    color: var(--lib-color-neutral-black);
  }
}
