body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  line-height: 1.2;
  background: var(--lib-background-body);
  color: var(--lib-color-neutral-900);
  font-size: var(--lib-base-font-size);
  font-family: var(--lib-font-familiy-sans);
}
a {
  min-height: auto;
}
