/* ==========================================================================
#SPACING-VARIABLES
========================================================================== */
//@use 'meta' as *;
//
//$spacing-unit: rem-calc(8px);
//$spacing-sides: $spacing-unit * 2;
//$spacing-sides\@tablet: $spacing-unit * 3;
//$spacing-sides\@desktop: $spacing-unit * 3;
//
//$spacing-xs: $spacing-unit;
//$spacing-md: $spacing-unit * 2;
//$spacing-lg: $spacing-unit * 3;
//$spacing-xl: $spacing-unit * 4;

@use "../01-settings/settings.breakpoints" as *;
@use '../02-tools/tools.functions' as *;

.gap-1 {
    gap: 1rem;
}

.gap-3 {
    gap: 1rem;

    @include md {
        gap: 3rem;
    }
}

.gap-05 {
    gap: 0.5rem;
}
