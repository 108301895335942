@use "../01-settings/settings.breakpoints" as *;
@use "../01-settings/settings.colours" as *;
@use "../01-settings/settings.fonts" as *;
@use "../01-settings/settings.variables" as *;
@use "../02-tools/tools.functions" as *;

.c-table {
  width: 100%;
  height: auto;
  overflow-x: auto;
  background-color: var(--lib-color-neutral-white);
  font-family: var(--lib-font-family-sans);
  table-layout: fixed;

  @include lg {
    margin: rem-calc(32px 0px 15px);
  }

  thead {
    font-weight: bold;
    background-color: var(--lib-color-neutral-white);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;

    tr {
      line-height: 1.25;
      font-size: 1.125rem;
      border-bottom: 1px solid var(--lib-color-brand-darkest);
      color: var(--lib-color-neutral-black);

      th {
        text-align: left;
        white-space: nowrap;
        cursor: pointer;

        div {
          display: flex;
          gap: $size-xxs;
        }

        &.date-col {
          width: 135px;
        }

        &.ship-to-col {
          width: 23%;

          @include xl {
            width: 30%;
          }
        }
      }
    }
  }

  th {
    border-bottom: 1px solid var(--lib-color-brand-darkest);
    padding: rem-calc(24px);
    text-align: left;
    vertical-align: top;
  }

  tbody {
    font-family: var(--lib-font-family-sans);
    font-weight: normal;
    line-height: 150%;
    font-size: $size-n;
    letter-spacing: $size-none;
    color: var(--lib-color-neutral-900);

    td {
      border-bottom: 1px solid var(--lib-color-neutral-200);
      padding: rem-calc(19px 13px 14px);
      text-align: left;
      vertical-align: top;
      overflow-wrap: break-word;
      &.td-long-content {
        overflow-wrap: break-word;
      }
    }

    a {
      display: inline;
      white-space: inherit;
    }
  }

  &--zebra {
    tbody {
      tr:nth-of-type(2n + 1) {
        background-color: var(--lib-color-neutral-50);
      }
    }
  }

  &--branded {
    thead {
      tr {
        background-color: var(--lib-color-brand-darkest);
        color: var(--lib-color-neutral-white);
        line-height: normal;
      }
    }

    tbody {
      color: var(--lib-color-neutral-black);

      tr {
        td {
          border-bottom: none;
        }

        &:last-child {
          border-bottom: 1px solid var(--lib-color-brand-lightest);
          margin-bottom: rem-calc(52px);
        }
      }

      tr:nth-of-type(2n + 1) {
        background-color: var(--lib-color-brand-lightest);
      }
    }

    &--two-col {
      tr {
        display: grid !important;
        padding: rem-calc(24px);
        grid-template-columns: 100%;

        @include sm {
          grid-template-columns: 50% 50%;
        }

        &:nth-child(2) {
          border-top: 1px solid var(--lib-color-brand-lightest);
        }

        &:last-child {
          border-bottom: 1px solid var(--lib-color-brand-lightest);
          margin-bottom: rem-calc(52px);
        }

        &:nth-of-type(odd) {
          background-color: var(--lib-color-brand-lightest) !important;
        }

        td {
          padding-bottom: rem-calc(12px);
          padding-left: 0;
          color: var(--lib-color-neutral-black);

          &:last-child {
            padding-bottom: 0;
          }

          &.header {
            font-weight: bold;
            line-height: 1.25;
            font-size: 1.125rem;
          }

          &.long-content-cell {
            overflow-wrap: break-word;

            a {
              display: inline;
              white-space: initial;
            }
          }
        }
      }
    }
  }
  &--small {
    thead tr,
    tbody tr {
      th {
        font-size: 1rem;
        padding: 1.5rem 0 1.5rem;
        text-align: center;
        font-weight: normal;
        border-right: 1px solid var(--lib-color-neutral-white);
        &:last-child {
          border-right: none;
        }
      }
      td {
        border-right: 1px solid var(--lib-color-neutral-200);

        text-align: center;
        vertical-align: middle;
        &:last-child {
          border-right: none;
        }
      }
    }
  }
}
app-order-details {
  .c-table--small thead tr th {
    font-size: 0.8rem;
  }
}
