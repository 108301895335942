/* You can add global styles to this file, and also import other style files */

$styleVersion: 6;
@import '@spartacus/styles/index';
@import '@spartacus/asm';
@import 'styles/spartacus/pdp';
@import 'assets/index';
@import '../../library/';
@import 'styles/spartacus/_product-list.scss';
@import 'styles/spartacus/_header.scss';
@import 'styles/spartacus/_footer.scss';
@import 'styles/spartacus/home-page';
@import 'styles/spartacus/_registration.scss';
@import 'styles/spartacus/_calendar.scss';
@import 'styles/spartacus/profilePreferences.scss';
@import 'styles/spartacus/influenza.scss';
@import 'styles/spartacus/_popover.scss';
@import 'styles/spartacus/account-overview';
@import 'styles/spartacus/overview-banner';
@import 'styles/spartacus/_need-assistance.scss';
@import 'styles/spartacus/_tools-and-resources.scss';
@import 'styles/spartacus/_patient-education.scss';
@import 'styles/spartacus/_smart_edit.scss';
@import 'styles/spartacus/_devices';
@import 'styles/spartacus/login-form';
@import 'styles/upgrade';
@import 'styles/components/custom-cms-paragraph';
@import 'styles/spartacus/product-monograph';
@import 'styles/spartacus/modal';

body {
  font-family: 'NotoSans', sans-serif;
}
h2,
h3,
h4 {
  font-family: var(--lib-font-family-sans);
}
cx-page-layout.LandingPage2Template cx-page-slot cx-banner cx-media img,
cx-page-layout.HomePageTemplate cx-page-slot cx-banner cx-media img {
  display: block;
  height: auto;
  position: initial;
  zoom: normal;
}
.head-catalog {
  background-image: url('../src/assets/icons/medicine-purple.png');
  width: 89px;
  height: 88px;
  background-position: center;
  background-repeat: no-repeat;
}
.head-simple {
  background-image: url('../src/assets/icons/to-do-list-purple.png');
  width: 89px;
  height: 88px;
  background-position: center;
  background-repeat: no-repeat;
}
.head-services {
  background-image: url('../src/assets/icons/hospital-icon-purple.png');
  width: 89px;
  height: 88px;
  background-position: center;
  background-repeat: no-repeat;
}
cx-media.is-missing {
  height: fit-content;
}

app-account-registration .ng-select .ng-select-container {
  min-height: 25px;
  max-height: 45px;
}

cx-navigation-ui nav {
  outline: none;
  margin-bottom: 0px !important;
}

.FluSeasonViewCartPageTemplate {
  cx-page-slot.FluCartMarketBannerContentSlot.has-components {
    display: none;
  }
}

app-account-registration .ng-select .ng-select-container,
.ng-select.ng-select-single .ng-select-container {
  height: 44px !important;
  overflow: initial;
  border-radius: 4px;
  box-shadow: inset 0 1px 5px 0 #d4d9de;
  border: solid 1px #d4d9de;
  background-color: #ffffff;
  margin-bottom: 5px;
}

.AccountPageTemplate {
  cx-page-slot.BodyContent.has-components {
    min-width: 100% !important;
    width: 68%;
  }
}

::ng-deep .my-class .modal-dialog {
  max-width: 80%;
  width: 80%;
}

.online-invoices {
  margin: 0px 0px 18px 70px;
}

.open_aside {
  padding: 0 32px !important;
}

.float-right {
  float: right;
}

@media only screen and (min-width: 992px) {
  .row-20 {
    margin-right: -20px;
    margin-left: -20px;
  }
}

@media (max-width: 767.98px) {
  .modal-dialog,
  cx-anonymous-consent-dialog .cx-anonymous-consent-dialog,
  cx-page-layout.AccountPageTemplate
    cx-replenishment-order-cancellation-dialog
    .cx-cancel-replenishment-dialog-foreground {
    margin: 0px !important;
    min-width: 78% !important;
    max-width: 100% !important;
  }
}

.invoice-paymentreview
  #sanofiOnlinePayInfoForm
  .sec-part
  aside
  > .row:nth-child(1)
  .title {
  margin-top: 32px;
}

.sec-part .title {
  color: #5b646e;
  font-family: 'NotoSansBold', sans-serif;
  font-size: 0.75rem;
  font-weight: 800;
}

.invoice-paymentreview
  #sanofiOnlinePayInfoForm
  .sec-part
  aside
  > .row:nth-child(1)
  .total {
  padding-top: 32px;
}

.sec-part .amount {
  padding: 0 11px;
}

.sec-part .total {
  color: #5b646e;
  text-align: right;
  font-size: 0.75rem;
}

.sec-part .para {
  margin-top: -10px;
  font-size: 0.75rem;
}

@media (min-width: 1200px) {
  .sec-part .open_aside .paytotal {
    width: 399px;
    padding: 0 10px;
    margin-left: -10px !important;
  }
}

.sec-part .paytotal {
  background-color: #f4f7f9;
  height: 24px;
  margin: 0;
}

.pl0 {
  padding-left: 0;
}

.sec-part .paytotal h3 {
  padding-top: 5px;
  margin: 0;
}

.pr0 {
  padding-right: 0;
}

.sec-part .paytotal p {
  padding-top: 5px;
  margin: 0;
}

.sec-part .reviewbtn {
  width: 380px;
  height: 44px;
  border-radius: 4px;
  background-color: #0057a6;
  color: #fff;
  font-size: 0.875rem;
  font-family: 'NotoSansBold', sans-serif;
  margin: auto;
  display: block;
  margin-top: 25px;
  border: 2px solid #0057a6;
}

a {
  cursor: pointer;

  text-decoration: underline;
}
.ContactUs-left a {
  span.ml-2 {
    color: var(--lib-color-brand-base);
    font-size: 1rem;
    text-underline-offset: 3px;
    &:hover {
      text-decoration: underline;
    }
  }
}

.sec-part .cancelbtn {
  width: 380px;
  height: 44px;
  border-radius: 4px;
  background-color: #fff;
  color: #0057a6;
  font-size: 0.875rem;
  font-family: 'NotoSansBold', sans-serif;
  margin: auto;
  display: block;
  margin-top: 8px;
  border: 2px solid #0057a6;
}

@media only screen and (min-width: 992px) {
  .invoice-paymentreview .light-line {
    margin: -10px;
    margin-bottom: 38px;
    padding: 16px;
    border-bottom: 4px solid #edf6fb !important;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .invoice-paymentreview .light-line {
    margin: -10px;
    margin-bottom: 38px;
    padding: 10px;
    border-bottom: 4px solid #edf6fb !important;
  }
  .online-step2 .po-right {
    padding-left: 15px !important;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .online-step2 .online-invoices {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .online-step2 .po-right {
    padding-left: 15px !important;
  }
}

@media screen and (min-width: 319px) and (max-width: 767px) {
  .float-right {
    float: left !important;
  }
}

@media screen and (min-width: 319px) and (max-width: 767px) {
  .open_aside {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 480px) {
  .row-20 {
    margin-right: -12px;
    margin-left: -12px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .sec-part .reviewbtn {
    width: 100% !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .sec-part .cancelbtn {
    width: 100% !important;
  }
}

.col-xs-6 {
  width: 50%;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type='number'] {
  -moz-appearance: textfield;
}

/* Select Box */

.ng-select .ng-select-container {
  height: 4px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  header cx-navigation-ui.flyout h5:hover,
  cx-navigation-ui.accNavComponent cx-generic-link:hover {
    color: #ffffff;
  }
  @media (min-width: 768px) {
    .EmptyCartMiddleContent app-quick-order cx-searchbox label {
      width: 27vw;
      min-width: 699px;
      max-width: 694px;
    }
  }
}

.custom-card-header cx-media img {
  width: 100%;
  height: 191px;
}

.modal-open .modal {
  overflow-x: auto;
  overflow-y: auto;
  width: 100%;
  height: 100%;
}

@media (min-width: 992px) {
  app-user-registration .singleHeader .col-lg-3 {
    max-width: 100%;
  }
  app-account-registration .singleHeader .col-lg-3 {
    max-width: 100%;
  }
}

.ng-select {
  float: right;
  min-width: 73%;
}

app-account-registration .custom-control {
  padding-left: 0px;
  &.custom-radio {
    padding-left: 0px;
  }
}

.ng-select .ng-select-container,
.ng-select.ng-select-single .ng-select-container {
  height: 28px;
  overflow: initial;
}

app-account-registration .ng-select .ng-select-container,
.ng-select.ng-select-single .ng-select-container {
  margin: 0px;
}

.ng-select .ng-arrow-wrapper .ng-arrow,
.ng-select.ng-select-single .ng-arrow-wrapper .ng-arrow {
  border: solid var(--lib-color-neutral-600);
  border-width: 0 1.8px 1.8px 0;
  display: inline-block;
  padding: 3px;
  margin: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.ng-dropdown-panel .scroll-host {
  padding: 0 10px;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 276px !important;
}

.ng-select
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-marked,
.ng-select.ng-select-single
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-marked {
  background-color: #ffffff;
}

.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected
  .ng-option-label,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked
  .ng-option-label {
  font-weight: normal;
}

.ng-dropdown-panel.ng-select-bottom {
  margin-top: 2px;
}

.ng-dropdown-panel.ng-select-bottom
  .ng-dropdown-panel-items
  .ng-option:last-child {
  border-bottom: none;
}

@media screen and (min-width: 300px) and (max-width: 650px) {
  .ng-select {
    width: 100%;
  }
  app-account-registration .ng-select {
    max-width: 100%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  cx-page-layout.ProductListPageTemplate .ProductLeftRefinements,
  cx-page-layout.ProductGridPageTemplate .ProductLeftRefinements {
    max-width: 24%;
    padding-top: 60px;
    margin-left: 0px;
    margin-right: 6px;
    padding-inline-end: 15px;
    padding-bottom: 15px;
    padding-inline-start: 15px;
  }
  @media (min-width: 768px) {
    .EmptyCartMiddleContent app-quick-order cx-searchbox label {
      width: 27vw;
      min-width: 279px;
      max-width: 550px;
    }
  }
  .row {
    display: flex;
    flex-wrap: wrap;
  }
  .selectBox .ng-select,
  .ng-select div,
  .ng-select input,
  .ng-select span {
    box-sizing: border-box;
    width: 100%;
    margin: 5px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .selectBox .ng-select,
  .ng-select div,
  .ng-select input,
  .ng-select span {
    box-sizing: border-box;
    width: 100%;
    margin: 5px 5px 5px 0px !important;
  }
  .LandingPage2Template cx-paragraph .home-banner-left h2,
  .HomePageTemplate cx-paragraph .home-banner-left h2 {
    position: absolute;
    margin: 26px 0px 0px 0px !important;
    font-size: 30px !important;
  }
  cx-active-facets h4 {
    margin: 10px 10px 0px 0px !important;
  }
  @media (max-width: 991.98px) {
    cx-active-facets h4 {
      display: flex;
    }
  }
  app-account-registration .ng-select {
    float: right;
    min-width: 90%;
    max-width: 100%;
  }
  .bottom-pagination-center .mx-auto cx-pagination {
    margin: -6px 0px 0px 0px;
    padding: 0px 0px 0px 241px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .ng-select,
  .ng-select div,
  .ng-select input,
  .ng-select span {
    margin: 2px;
  }
  .selectBox .ng-select,
  .ng-select div,
  .ng-select input,
  .ng-select span {
    box-sizing: border-box;
    width: 100%;
    margin: 5px;
  }
}

@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
  .bottom-pagination-center cx-pagination {
    margin: -22px 0px 0px 0px;
    padding: 0px 0px 0px 272px;
  }
  @media screen and (min-width: 300px) and (max-width: 650px) {
    .ng-select {
      width: 100%;
    }
  }
  @media screen and (min-width: 926px) {
    .selectBox .ng-select,
    .ng-select div,
    .ng-select input,
    .ng-select span {
      box-sizing: border-box;
      width: 100%;
    }
  }
  @media (max-width: 991.98px) {
    cx-active-facets h4 {
      margin: 10px 10px 0px 0px;
    }
    app-account-registration .ng-select {
      float: right;
      min-width: 80%;
    }
    .selectBox .ng-select,
    .ng-select div,
    .ng-select input,
    .ng-select span {
      box-sizing: border-box;
      width: 100%;
      margin: 5px;
    }
    cx-page-layout.ProductListPageTemplate .ProductLeftRefinements,
    cx-page-layout.ProductGridPageTemplate .ProductLeftRefinements {
      max-width: 30%;
      padding-top: 60px;
      margin-left: 53px;
      margin-right: 69px;
      padding-inline-end: 15px;
      padding-bottom: 15px;
      padding-inline-start: 15px;
    }
    .top cx-pagination {
      margin: -22px 0px 0px 0px;
      padding: 0px 0px 0px 228px;
    }
  }
  cx-active-facets h4 {
    margin: 10px 10px 0px 0px;
  }
  app-account-registration .ng-select {
    float: right;
    min-width: 80%;
  }
  .selectBox .ng-select,
  .ng-select div,
  .ng-select input,
  .ng-select span {
    box-sizing: border-box;
    width: 100%;
    margin: 5px;
  }
  cx-page-layout.ProductListPageTemplate .ProductLeftRefinements,
  cx-page-layout.ProductGridPageTemplate .ProductLeftRefinements {
    max-width: 30%;
    padding-top: 60px;
    margin-left: 53px !important;
    margin-right: 69px !important;
    padding-inline-end: 15px;
    padding-bottom: 15px;
    padding-inline-start: 15px;
  }
  .top cx-pagination {
    margin: -22px 0px 0px 0px;
    padding: 0px 0px 0px 228px;
  }
  cx-facet a {
    cursor: auto;
    padding: 0.35rem 0;
  }
  cx-facet a span {
    cursor: pointer;
  }
  @media screen and (min-width: 300px) and (max-width: 650px) {
    .slick-slider {
      max-width: 324px !important;
    }
    .slick-slide .unit-calc .price-details {
      width: 40% !important;
    }
  }
  @media screen and (min-width: 700px) and (max-width: 1024px) {
    .slick-slider {
      max-width: 436px !important;
    }
    .slick-slide .price-details {
      width: 34% !important;
    }
  }
  .slick-dots {
    top: -58px;
    right: -12px;
    overflow: hidden;
    bottom: 0;
    width: auto !important;
    > li {
      display: inline-block;
    }
    > li:only-child {
      display: none;
    }
  }
  .slick-dots li button:before {
    font-size: 0.6rem !important;
  }
  .slick-dots li.slick-active button:before {
    color: #0057a6 !important;
    opacity: 1 !important;
  }
  .slick-dots li button:hover:before {
    color: #bfc4c8;
  }
  .slick-dots li button {
    background: #bfc4c8;
  }
  @media (min-width: 1280px) {
    .slick-slider {
      display: contents;
      background: rgb(14, 13, 13);
      position: inherit !important;
    }
    .slick-slide .unit-calc .price-details {
      width: 40% !important;
    }
  }
  .slick-slider {
    max-width: 750px;
    min-width: 300px;
  }
  body .slick-prev,
  body .slick-next {
    height: 45px;
    width: 40px;
    z-index: 100;
  }
  .slick-prev.slick-arrow {
    width: 25px;
    height: 50px;
    z-index: 5;
    position: absolute;
    top: 50%;
    display: block;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    box-shadow: -2px 0 19px 0 rgb(23 114 183 / 20%);
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    margin: 0 26px;
  }
  .slick-next.slick-arrow {
    width: 25px;
    height: 50px;
    z-index: 5;
    position: absolute;
    top: 50%;
    display: block;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    box-shadow: -2px 0 19px 0 rgb(23 114 183 / 20%);
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    margin: 0 26px;
  }
  .slick-slide .custom-product-card .custom-card-body {
    padding: 7px;
  }
  .slick-slide .custom-product-card .custom-tab-p {
    min-height: 151px;
    margin-bottom: 0;
  }
  .slick-slide .custom-product-card .display-type p {
    margin-bottom: 0;
  }
  .slick-slide .custom-product-card .custom-card-header .product-img {
    width: 210px;
  }
  .slick-track {
    margin: 0 !important;
  }
  .slick-slide .custom-product-card {
    padding: 20px !important;
    max-height: 695px !important;
  }
  .slick-prev:before,
  .slick-next:before {
    content: '' !important;
    width: 12px;
    height: 12px;
    background: none;
    padding: 0;
    border: 0;
    outline: 0;
    border-top: 2px solid #0057a6;
    border-right: 2px solid #0057a6;
    cursor: pointer;
    position: absolute;
    left: 6px;
    top: 20px;
  }
  .slick-prev:before {
    transform: rotate(225deg);
  }
  .slick-next:before {
    transform: rotate(45deg);
  }
  .slick-slide img {
    display: inline !important;
  }
  @media (min-width: 1280px) {
    cx-storefront main .ProductGridPageTemplate {
      margin: 0 3.5rem !important;
    }
  }
  .container-pdp cx-media img {
    max-width: 100% !important;
  }
  .Section2B {
    position: relative;
    min-height: 380px;
  }
  cx-page-layout.LandingPage2Template cx-page-slot.Section2A,
  cx-page-layout.LandingPage2Template cx-page-slot.Section2B,
  cx-page-layout.HomePageTemplate cx-page-slot.Section2A,
  cx-page-layout.HomePageTemplate cx-page-slot.Section2B {
    --cx-flex-basis: 100% !important;
    max-width: 1300px !important;
  }
  .LandingPage2Template cx-paragraph .home-banner-left h2,
  .HomePageTemplate cx-paragraph .home-banner-left h2 {
    position: absolute;
    margin: 137px 0px 0px 0px;
    z-index: 10;
    width: 305px;
    font-size: 40px;
    color: #2a343f;
    line-height: 44px;
    font-family: 'Poppins-medium', sans-serif;
  }
  @media screen and (min-width: 300px) and (max-width: 650px) and (orientation: portrait) {
    .LandingPage2Template cx-paragraph .home-banner-left h2,
    .HomePageTemplate cx-paragraph .home-banner-left h2 {
      position: absolute;
      z-index: 10;
      font-size: 32px !important;
      color: #2a343f;
      line-height: 44px;
      font-family: 'Poppins-medium', sans-serif;
      margin: 30px 0px 0px 10px;
    }
    .Section2B vsca-cx-login-form.user-form {
      position: absolute;
      right: 16px;
      width: 43% !important;
      top: -7px;
    }
    .Section2B .is-initialized {
      margin: 0px 0px 0px 0px;
    }
    .modal-content cx-facet button.heading {
      border-top: 1px solid #eee;
    }
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    border-bottom: 1px solid #e8e8e8;
    padding: 13px 10px;
  }
  input[type='radio']:checked::after {
    border-color: blue !important;
    background-color: white !important;
  }
  input[type='radio']:checked::before {
    border-color: white !important;
    background-color: blue !important;
  }
  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .AccountPageTemplate {
      cx-page-slot.BodyContent.has-components {
        padding: 0;
        min-width: 60% !important;
        float: left;
        border-right: 1px solid #edf6fb;
      }
    }
    .Section2B vsca-cx-login-form.user-form {
      position: absolute;
      right: 40px !important;
      width: 43.5% !important;
      top: -7px !important;
    }
  }
  @media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .AccountPageTemplate {
      cx-page-slot.BodyContent.has-components {
        padding: 0;
        min-width: 100% !important;
        float: left;
        border-right: 1px solid #edf6fb;
      }
    }
    .LandingPage2Template cx-paragraph .home-banner-left h2,
    .HomePageTemplate cx-paragraph .home-banner-left h2 {
      position: absolute;
      margin: 26px 0px 0px 0px;
      width: 100%;
      font-size: 30px !important;
    }
    .login-details {
      padding: 0 32px 0px;
    }
    .Section2B vsca-cx-login-form.user-form {
      position: absolute;
      right: 69px !important;
      top: -6px !important;
    }
  }
  @media screen and (min-width: 300px) and (max-width: 760px) {
    .AccountPageTemplate {
      cx-page-slot.BodyContent.has-components {
        padding: 0;
      }
    }
  }
  cx-media.cx-product-image.is-initialized.is-missing {
    display: none;
  }
  .content-right .bottom .right .ng-dropdown-panel-items > div {
    padding: 0;
  }
  .content-right .bottom .right .ng-dropdown-panel {
    box-shadow: 0 0px 5px rgb(23 114 183 / 20%);
    border: 0;
    border-radius: 4px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .content-right
      .bottom
      .right
      .ng-dropdown-panel
      .ng-dropdown-panel-items
      .ng-option {
      margin: 0 0 0 -9px;
    }
  }
}

.makePaymentView {
  background-color: #ffffff;
}

.registrationView {
  background-color: #ffffff;
}

@media (min-width: 1280px) {
  .makePaymentView {
    background-color: #ffffff;
  }
  .registrationView {
    width: 1280px;
  }
}

.modal-dialog {
  width: fit-content;
  max-width: 1280px;
}

@media only screen and (min-width: 992px) {
  .Section2B vsca-cx-login-form.user-form {
    position: absolute;
    right: 16px;
    width: 32%;
    top: -9px;
  }
  .modal-dialog {
    width: fit-content;
    max-width: 1280px;
  }
  @media only screen and (min-width: 992px) {
    .Section2B vsca-cx-login-form.user-form {
      position: absolute;
      right: 10%;
      width: 32%;
      top: -9px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .LoginPageTemplate .LeftContentSlot .customer-login {
      display: block;
    }
    .LoginPageTemplate .LeftContentSlot .visible-xs {
      display: none !important;
    }
  }
  app-root {
    min-height: 500px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .product-details-modal .modal-dialog {
    width: 93.5%;
  }
}

.product-details-inf-modal .modal-dialog {
  max-width: 93.5%;
}

app-custom-pdp cx-media img {
  max-width: 100%;
}

@media (min-width: 576px) {
  cx-page-layout.ProductDetailsPageTemplate cx-page-slot.Summary {
    max-width: 647px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
  .AccountPageTemplate {
    cx-page-slot.BodyContent.has-components {
      padding: 0;
      min-width: 100% !important;
    }
  }
  .Section2B cx-banner {
    height: auto;
  }
  .CartPageTemplate {
    max-width: initial;
    padding: 0 !important;
    margin: 0 !important;
    display: block !important;
  }
  app-order-products app-quick-order .quick-prod-details .price-details {
    padding: 0px 0px 30px 0px;
    width: 41% !important;
  }
  app-order-products app-quick-order > .quick-prod-details .units {
    width: 41%;
  }
  cx-searchbox #quickOrder {
    display: block;
    position: inherit !important;
    top: 0 !important;
  }
  .EmptyCartMiddleContent {
    display: block !important;
  }
  .form-horizontal .ng-select {
    width: 128px;
  }
  .product-details-modal .modal-dialog {
    max-width: 375px !important;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) {
  app-order-products app-quick-order .quick-prod-details .units {
    width: 45% !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  app-order-products app-quick-order .quick-prod-details .dosessection {
    width: 100% !important;
    text-align: center;
    padding: 0px;
  }
  app-order-products app-quick-order .quick-prod-details .units {
    width: 62%;
  }
  app-order-products app-quick-order .quick-prod-details .blue-button {
    width: 100% !important;
    margin: 10px 0px 0px 0px;
  }
  app-order-products app-quick-order cx-searchbox > .searchbox {
    min-width: 238px;
    max-width: 258px;
  }
}

.loc-Search-Icon {
  background-image: url('../src/assets/icons/search.png');
  background-size: 26px;
  width: 26px;
  height: 26px;
  display: block;
}

ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  overflow: visible !important;
  text-overflow: none !important;
}

.payment-drpdwn
  ng-select
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected {
  display: none;
}

.payment-drpdwn
  ng-select
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option {
  font-family: 'NotoSansBold', sans-serif;
  color: #2a343f;
}

.emails-ngSelect-Div
  ng-select
  .ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option {
  color: #337ab7;
}

.calender .form-horizontal .input-append .add-on .icon-th {
  background-image: url('../src/assets/icons/calendar.svg');
  background-repeat: no-repeat;
  background-size: 30px;
  padding: 5px 15px;
}

@media only screen and (min-width: 992px) {
  .CartPageTemplate {
    max-width: 100% !important;
  }
}

// Order Details
cx-order-details-shipping,
cx-order-details-totals {
  display: none;
}

.modal-md .modal-content {
  width: 35%;
  margin: 0px 0px 0px 425px;
}

.patient-edu-modal .modal-md .modal-content {
  width: 100%;
  margin: 0px 0px 0px 0px;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .modal-md .modal-content {
    width: 55% !important;
    margin-left: 190px !important;
  }
  .patient-edu-modal .modal-md .modal-content {
    width: 100%;
    margin-left: 0px !important;
  }
}

@media only screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
  .modal-md .modal-content {
    margin: 190px 0px 0px 50px;
    width: 70%;
  }
  .modal-md .blue-button {
    margin: 0px 0px 15px 0px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
  .modal-md .modal-content {
    margin: 0px 0px 0px 275px;
  }
  .patient-edu-modal .modal-md .modal-content {
    margin: 0px 0px 0px 0px !important;
  }
  .modal-md .blue-button {
    margin: 0px 0px 10px 0px;
  }
}

.form-horizontal .ng-select {
  display: inline-block;
  float: none;
  min-width: 128px;
  margin-left: 14px;
}

.form-horizontal .ng-select .ng-clear-wrapper,
.form-horizontal .ng-select .ng-arrow-wrapper {
  display: none;
}

.form-horizontal .ng-select .ng-select-container {
  background-image: url('../src/assets/icons/waitlist.png');
  background-repeat: no-repeat;
  background-position: 5px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .form-horizontal.ng-select,
  .form-horizontal .ng-select div,
  .form-horizontal .ng-select input,
  .form-horizontal .ng-select span {
    margin: 0px;
  }
  .patient-edu-modal .modal-md .modal-content {
    width: 100% !important;
    margin-top: 10% !important;
  }
}

.form-horizontal .ng-select .ng-select-container .ng-value-container {
  padding: 0 0 0 37px;
  font-size: 14px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .form-horizontal .ng-select {
    margin: -2px 0 0 14px;
  }
  .patient-edu-modal .modal-md .modal-content {
    width: 100% !important;
    margin-top: 10% !important;
  }
}

@media only screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
  .product-details-modal .modal-dialog {
    max-width: 375px !important;
  }
  .patient-edu-modal .modal-md .modal-content {
    width: 100%;
    margin-top: 0px;
    margin-left: 0px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .product-details-modal .modal-dialog {
    max-width: 768px;
  }
}

@media only screen and (min-device-width: 1280px) {
  .product-details-modal .modal-dialog {
    max-width: 1280px;
  }
  .product-details-inf-modal .modal-dialog {
    max-width: 1240px;
  }
}

@media (min-width: 768px) {
  app-quick-order cx-searchbox {
    position: relative;
    z-index: 9;
  }
}

cx-searchbox #quickOrder {
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  color: #000;
  background-color: #fff;
  font-size: var(--cx-font-small, 1rem);
  max-height: 136px;
  overflow-y: auto;
}

cx-searchbox #quickOrder {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

cx-searchbox #quickOrder::-webkit-scrollbar {
  width: 4px;
}

/* Track */

cx-searchbox #quickOrder::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #808080;
  border-radius: 10px;
}

/* Handle */

cx-searchbox #quickOrder::-webkit-scrollbar-thumb {
  width: 4px;
  background-color: #424141;
  border-radius: 10px;
}

/* Handle on hover */

cx-searchbox #quickOrder::-webkit-scrollbar-thumb:hover {
  width: 4px;
  background-color: #424141;
}

.form-horizontal .ng-select {
  display: inline-block;
  float: none;
  min-width: 128px;
  margin-left: 14px;
}

.form-horizontal .ng-select .ng-clear-wrapper,
.form-horizontal .ng-select .ng-arrow-wrapper {
  display: none;
}

.form-horizontal .ng-select .ng-select-container {
  background-image: url('../src/assets/icons/waitlist.png');
  background-repeat: no-repeat;
  background-position: 5px;
  margin: 1px 0px -6px 0px !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .form-horizontal.ng-select,
  .form-horizontal .ng-select div,
  .form-horizontal .ng-select input,
  .form-horizontal .ng-select span {
    margin: 0px;
  }
  app-order-products app-quick-order .quick-prod-details .blue-button {
    width: 91%;
  }
}

.form-horizontal .ng-select .ng-select-container .ng-value-container {
  padding: 0 0 0 37px;
  font-size: 14px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .form-horizontal .ng-select {
    margin: -2px 0 0 14px;
  }
}

@media only screen and (min-width: 992px) {
  .CartPageTemplate {
    max-width: 100% !important;
  }
}

.cart-delivery-date-picker .add-on .icon-th {
  background-image: url('../src/assets/icons/calendar.svg');
  background-repeat: no-repeat;
  background-size: 30px;
  padding: 5px 15px;
}

@media only screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
  .LoginPageTemplate .LeftContentSlot .visible-xs {
    display: none !important;
  }
}
.EmptyCartMiddleContent .preheader .middle-row a {
  width: 100%;
  display: inline-block;
  margin-bottom: 6px;
}
@media only screen and (min-width: 360px) and (max-width: 640px) and (orientation: landscape) {
  .customer-login {
    margin: 0px 0px 0px 0px !important;
  }
  cx-page-layout.LoginPageTemplate {
    min-height: 499px !important;
    width: 100%;
  }
  cx-page-layout.LoginPageTemplate .customer-login {
    width: 60% !important;
    display: block !important;
    left: 16.5%;
  }
  .LoginPageTemplate .LeftContentSlot .visible-xs {
    display: none !important;
  }
  .patient-edu-modal .modal-md .modal-content {
    width: 50% !important;
    margin-top: 0% !important;
    margin-left: 25% !important;
  }
}

@media only screen and (min-width: 360px) and (max-width: 640px) and (orientation: portrait) {
  .LoginPageTemplate .LeftContentSlot .visible-xs {
    display: none !important;
  }
  .LoginPageTemplate .LeftContentSlot .customer-login {
    display: block !important;
    position: fixed;
  }
  cx-page-layout.LoginPageTemplate {
    min-height: 548px !important;
    width: 100% !important;
  }
  cx-page-layout.LoginPageTemplate .customer-login {
    width: 96% !important;
    margin: 0px 0px 0px -17px;
    display: block !important;
  }
}

.sortBy-select .selectBox .ng-select,
.ng-select div,
.ng-select input,
.ng-select span {
  box-sizing: border-box;
  width: 100%;
  margin: 0 !important;
}

.popover-links-invoice {
  background-color: #5b646e !important;
}
.popover-header {
  font-size: 14px;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 10px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .product-details-modal .modal-dialog {
    max-width: 768px !important;
  }
  app-quick-order cx-searchbox {
    width: 100% !important;
    .searchbox {
      width: 100%;
      max-width: 100%;
      border: 1px solid #d4d9de;
      border-radius: 4px;
      box-shadow: inset 0 0 2px 0px #d4d9de;
      -webkit-box-shadow: inset 0 0 2px 0px #d4d9de;
    }
  }
}

.MakeAPaymentPageTemplate .BannerContent {
  width: 23%;
  max-width: 23%;
  padding: 10px;
  background: #fff;
  margin: 28px 0px 0px 0px;
  display: inline-block;
  text-align: center;
}

.MakeAPaymentPageTemplate .BodyContent {
  width: 78%;
  min-width: 78%;
}

.MakeAPaymentPageTemplate .BodyContent .makePaymentView {
  margin: 0px 0px 0px 0px;
}

@media only screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
  .MakeAPaymentPageTemplate .BannerContent {
    width: 100%;
    background: #fff;
    display: none;
  }
  app-account-overview
    > app-quick-order
    .MakeAPaymentPageTemplate
    cx-page-slot.BodyContent.has-components {
    min-width: 100%;
    margin: 48px 0px 0px 0px;
    width: 100%;
  }
  app-quick-order cx-searchbox label {
    border: 1px solid #d4d9de;
  }
  .cred-dropdown-mob .ng-dropdown-panel .scroll-host {
    padding: 0px;
    .ng-option {
      padding-left: 5px;
      font-size: 9px;
    }
  }
  .cred-dropdown-mob .ng-value-label {
    font-size: 7px;
  }
}

@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
  app-saved-orders lapp-quick-order cx-searchbox label {
    width: 27vw;
    min-width: 771px;
    max-width: 771px;
  }
  app-order-products app-quick-order > .quick-prod-details .price-details {
    padding: 0px 0px 0px 0px;
    width: 50% !important;
  }
  app-order-products app-quick-order .quick-prod-details .dosessection {
    width: 92% !important;
  }
  app-order-products app-quick-order .quick-prod-details .units {
    width: 50%;
  }
  app-order-products app-quick-order > cx-searchbox label {
    width: 27vw;
    min-width: 285px;
  }
}

.payment-drpdwn .ng-select,
.emails-ngSelect-Div,
.ng-select {
  caret-color: transparent !important;
}

cx-page-layout.MakeAPaymentPageTemplate {
  display: flex;
}

app-payment-details > .makePaymentView,
app-payment-review > .makePaymentView {
  margin: 28px 0px 0px 0px;
}

.ContentSlotHeaderBannerOrdersListing {
  max-width: 99% !important;
  padding-inline-end: 4px !important;
  padding-bottom: 0;
  padding-inline-start: 4px !important;
  padding-top: 0px !important;
}

.ContentSlotHeaderBannerOrdersListing cx-paragraph p {
  margin-bottom: 0%;
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .savedcart-right .cartProductSaveDetails app-quick-order cx-searchbox label {
    min-width: 222px;
  }
}

.new-res ul li {
  font-family: 'NotoSans', sans-serif;
  font-size: 14px;
  line-height: 36px;
  padding-left: 34px;
  background: url('../src/assets/icons/checkmark.svg') 0 4px no-repeat;
}

header .SiteContext cx-paragraph > p {
  background-image: url('../src/assets/icons/healthcare-logo.png');
  background-position: left;
  background-repeat: no-repeat;
  background-size: 15px;
  padding: 0 0 0 22px;
}

.ng-clear-wrapper {
  display: none !important;
}

.reservation-history-dropdown ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 240px !important;
}

.detailsEmail .ng-option,
.detailsEmail .ng-value-label {
  font-family: 'NotoSans', sans-serif;
  font-size: 14px;
  color: #495057 !important;
}

// Print Media
@import 'styles/spartacus/print';
header .SiteLogin {
  padding: 0 3rem 0.875rem 0 !important;
  display: block !important;
  width: 100% !important;
  background-color: #ffffff;
  cx-login {
    display: block !important;
    float: right;
    .cx-login-greet {
      padding: 0;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

@media screen and (min-width: 813px) and (max-width: 1024px) and (orientation: landscape) {
  header .SiteLogin {
    padding: 0 1rem 0.875rem 0 !important;
  }
}

@media screen and (min-width: 375px) and (max-width: 1024px) and (orientation: portrait) {
  header .SiteLogin {
    padding: 0 1.3rem 0.875rem 0 !important;
  }
}

input {
  -webkit-user-select: text;
  /* Chrome, Opera, Safari */
  -moz-user-select: text;
  /* Firefox 2+ */
  -ms-user-select: text;
  /* IE 10+ */
  user-select: text;
  /* Standard syntax */
}
#right-box .emails-ngSelect-Div .ng-select {
  font-size: 14px;
  margin-top: -4px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  #right-box .emails-ngSelect-Div .ng-select {
    float: none;
  }
}
@media screen and (min-width: 320px) and (max-width: 812px) {
  #right-box .emails-ngSelect-Div .ng-select {
    float: none;
  }
}
input[type='checkbox'],
input[type='radio'] {
  -webkit-appearance: checkbox !important;
  -moz-appearance: checkbox !important;
}

cx-page-layout.ProductDetailsPageTemplate cx-product-summary {
  display: none;
}

@media only screen and (max-width: 992px) {
  .noDisplay {
    display: none !important;
  }
}

ngb-modal-backdrop {
  z-index: 1050 !important;
}

.header .NavigationBar cx-navigation-ui.flyout > nav > ul > li > button {
  width: 85%;
}
