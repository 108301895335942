@use "../01-settings/settings.fonts" as *;
@use "../01-settings/settings.colours" as *;
@use "../02-tools/tools.functions" as *;

.c-financial-list,
.acc-order-details {
  .no-orders,
  .no-invoices,
  .no-credits {
    color: var(--lib-color-neutral-800);
    font-family: var(--lib-font-family-sans);
    font-size: rem-calc(24px);
    font-weight: 500;
    text-align: center;
  }

  .no-orders {
    padding-top: rem-calc(135px);
    padding-bottom: rem-calc(150px);
  }

  .no-credits,
  .no-invoices{
    padding-top: rem-calc(180px);
    padding-bottom: rem-calc(200px);
  }

  .red {
    color: var(--lib-color-error-red);
    display: flex;
  }
}
