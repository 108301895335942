/* Portrait and Landscape till Tablet */
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  @media only screen and (max-width: 1024px) {
    background-color: #0057a6;
    height: 2px;
  }
}

.header {
  .SearchBox {
    cx-searchbox {
      label {
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
          width: auto !important;
        }
      }
    }
  }

  .SiteLinks {
    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      display: flex;
    }

    cx-navigation {
      @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
        height: 40px;
        margin: 1px 0px 0px 0px;
      }
    }

    cx-link {
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        text-align: right;
        padding-right: 15px;
        height: auto;

        &:nth-of-type(2) {
          order: 1;
          padding-top: 2px;
        }
      }
    }
  }
}

// Remove the Mobile Horizontal Scroll
cx-page-layout {
  cx-page-slot {
    cx-paragraph {
      &:nth-child(4) {
        @media only screen and (min-width: 360px) and (max-width: 812px) {
          margin: 0 !important;

          p {
            margin: 0;
            p {
              padding: 0 20px 10px;
            }
          }
        }
      }
    }
    cx-banner {
      &:nth-child(3) {
        padding-bottom: 10px;
      }
    }
  }
  &.footer {
    cx-page-slot > cx-banner:nth-child(1) > cx-generic-link cx-media img {
      max-width: 380px;
    }
  }
}

cx-breadcrumb {
  h1 {
    @media only screen and (min-width: 360px) and (max-width: 812px) {
      white-space: normal;
    }
  }
}

.AccountPageTemplate {
  .inner-Contents-Font {
    .custom-control {
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        display: flex;
      }
      @media only screen and (min-width: 360px) and (max-width: 812px) {
        display: flex;
      }
    }
  }
  .edit-button-control {
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      flex: 0 0 20%;
      max-width: 20%;
    }
    @media only screen and (min-width: 360px) and (max-width: 812px) {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
}

#leftCont-rightNav-cart.checkout {
  .right-navigation {
    @media only screen and (min-width: 360px) and (max-width: 812px) {
      background: #fff;
      margin-top: 25px;
    }
  }
}
//Payment
.AccountPageTemplate {
  .name-Of-Account {
    @media screen and (min-width: 360px) and (max-width: 812px) {
      flex: 0 0 auto !important;
    }
  }
  .myAccount-Col-Span {
    @media screen and (min-width: 360px) and (max-width: 812px) {
      padding: 0 !important;
      border-right: 4px solid #edf6fb;
    }
  }
  .My-Account-Container {
    .my-Account-Main-Header {
      @media screen and (min-width: 360px) and (max-width: 812px) {
        margin: 0;
      }
    }
  }
}

.FluSeasonPaymentPageTemplate {
  .en-div {
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      margin-left: 66% !important;
      flex: 0 0 30%;
      max-width: 30%;
    }
  }
}

// .preheader span,
// .preheader a {
//   @media only screen and (min-width: 768px) and (max-width: 1024px) {
//     position: relative;
//     top: -15px;
//   }
// }

//Invoice Table
#applyCreditsTable {
  td.pay {
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      &:nth-of-type(1) {
        display: flex;
        justify-content: space-between;
        width: max-content;
      }
    }
  }
}

// IOS checkbox fixes
.checkbox input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
