@media print {
  .no-print {
    display: none !important;
  }

  /* ---- Global Styling ---- */
  .PreHeader,
  .preheader,
  cx-breadcrumb span a,
  .SiteContext,
  .SiteLinks,
  .MiniCart,
  .SearchBox,
  .SiteLogin,
  .NavigationBar,
  .Footer,
  app-standard-cart .header,
  app-quick-order,
  .spl-add a,
  .right-navigation .ctn-button,
  .right-navigation .edit-button,
  .right-navigation .edit-Btn,
  .right-navigation .continue-Btn,
  .continue-Btn,
  .bc-Dash-Div {
    @extend .no-print;
  }

  cx-storefront {
    min-height: auto;
    main {
      display: block !important;
    }
  }

  .container {
    min-width: inherit !important;
  }

  body.modal-open {
    header,
    .BottomHeaderSlot {
      @extend .no-print;
    }
    cx-storefront main {
      @extend .no-print;
    }
  }

  /* ---- Cart Section Styling ---- */

  .cart-accordion {
    .pdp-list-data {
      width: 100%;

      .cart_print {
        a {
          display: flex;

          h1 {
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
      }
      .row {
        &:nth-of-type(2) {
          display: flex;
          justify-content: space-between;
        }
      }
      .product-price,
      .unitCount-comp {
        margin-top: -20px;
      }
    }
    .add-on img[alt~="close"] {
      @extend .no-print;
    }
    .deli_loc {
      display: flex;
      justify-content: space-between;
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    width: 16px;
    height: 16px;
    content: "";
    border: 4px solid #0057a6;
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    content: "";
    width: 1px;
    position: absolute;
    height: 1px;
    border-radius: 50%;
  }

  /* ---- Account Statement Section Styling ---- */
  cx-storefront {
    &.AccountPageTemplate {
      .makePaymentView {
        @extend .no-print;
      }
    }
  }

  .spl-add {
    margin-top: 10px;
  }

  #applyCreditsTable th,
  #applyCreditsTable td {
    border-top: 1px solid #dee2e6;
    text-align: center;
    padding: 0.55rem;
  }

  #applyCreditsTable {
    border-bottom: 1px solid #dee2e6;
  }

  #applyCreditsTable th {
    font-weight: bold;
    color: #222;
  }
  .sub_total.lower-section {
    td.tot,
    td.cad {
      font-size: 12px;
    }
  }

  /* ---- Checkout Cart Section Styling ---- */
  .CartPageTemplate {
    .BottomHeaderSlot {
      h1 {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
  .influenzaCategory-Container {
    aside.right-navigation,
    .right-Section-Top-Col-Span {
      width: 30%;
    }

    section.float-left.border-Right {
      width: 68%;
    }
    .shipping-Bill-Main-Section {
      display: flex;
      justify-content: space-between;

      section.po-Section-iPad,
      section.shipTo-Main-Section,
      section.total-Price-Section {
        flex: 0 0 25%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      section.total-Price-Section {
        max-width: 35%;
        flex: 0 0 35%;
      }
    }
  }

  header .SiteLogo {
    margin: 0;
  }

  /*----- Checkout Section Styling ---- */
  .reservation-Container .edit-Icon-Img,
  .reservation-Container .edit-Click {
    @extend .no-print;
  }

  /*----- Checkout Order Confirmation  Styling---- */
  .ConfirmOrderPageTemplate {
    .order-details {
      &.shipto-label {
        flex: auto;
      }
    }
    .order-update {
      margin-bottom: 0;
    }
  }

  /*----- Order History Styling ---- */
  app-order-details {
    .details-header {
      @extend .no-print;
    }
  }
  .savings-total {
    .blue-button,
    .white-button {
      @extend .no-print;
    }
  }
  .AccountPageTemplate {
    .orderDetailTable {
      table {
        th {
          color: #2a343f !important;

          &:last-child {
            border-right: 1px solid #549cc9;
          }
        }
      }
    }
  }

  /*----- Global Flu Structure Styling---*/
  .cx-storefront main {
    .influenzaCategory-Container {
      .shipping-Bill-Main-Section {
        justify-content: flex-start;
      }
      .right-Section-Top-Col-Span {
        flex: 0 0 30%;
      }
      section.border-Right {
        flex: 0 0 68%;
      }
    }
  }

  /*----- Flu Details Section Styling ---- */
  .flureservationoverviewpagetemplate {
    .back-To-Dashbaord,
    .print-Div {
      @extend .no-print;
    }
  }
  .dose-total-Section-reservation-details {
    left: 57% !important;
  }

  /*----- Flu Section Styling ---- */
  .FluViewCartSlot {
    &.has-components {
      .bc-Dash-Div,
      .delte-Div,
      .print-Div {
        @extend .no-print;
      }
    }
  }

  /*----- Flu Checkout Section Styling ---- */
  .FluSeasonViewCartPageTemplate {
    .units-area {
      text-align: center;
      margin: 5px 15px 5px 0;
      span {
        @extend .no-print;
      }
    }
    .delete-popover-container {
      @extend .no-print;
    }
    .right-Section-Top-Col-Span {
      flex: 0 0 30%;
    }
    section.border-Right {
      flex: 0 0 68%;
    }
  }
  /*----- Flu Payment Section Styling ---- */
  .FluSeasonPaymentPageTemplate {
    .bc-Dash-Div {
      @extend .no-print;
    }
    .est-Main-Container .ctn-button,
    .est-Main-Container .edit-button,
    .est-Main-Container .edit-Btn,
    .est-Main-Container .continue-Btn {
      @extend .no-print;
    }
  }

  /*---- Flu Select Vaccines ---*/
  .FluSeasonProductListPageTemplate {
    .unit-area {
      span {
        @extend .no-print;
      }
    }
    .right-Section-Top-Col-Span {
      flex: 0 0 30%;
    }
    section.bord-Right {
      flex: 0 0 68%;
      max-width: 68%;
    }
    .banner-Section {
      &.small-Device-Section {
        flex: 0 0 30%;
        max-width: 30%;
        display: inline-block;
      }
    }
  }
  /*----------Make a Payment --------*/
  .MakeAPaymentPageTemplate {
    margin-top: 20px;
  }
}
